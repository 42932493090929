import { authorizedPerson } from '../../defaultStates'
import {
  SET_AUTHORIZED_PERSON,
  SET_EMPTY_AUTH,
  SET_UPDATE_AUTHORIZED_PERSON,
} from '../../ActionTypes'

export default (state = authorizedPerson, action = {}) => {
  switch (action.type) {
    case SET_AUTHORIZED_PERSON:
      return {
        ...state,
        [action.key]: action.value,
      }
    case SET_UPDATE_AUTHORIZED_PERSON:
      return {
        ...action.authorizedPerson,
      }
    case SET_EMPTY_AUTH:
      return {
        ...action.auth,
      }
    default:
      return state
  }
}
