import React, { useEffect } from 'react'
import styles from './pickList.module.scss'
import cx from 'classnames'

const PickList = ({ selectedValue, options, handleItemClick, coords }) => {
  const pickListNode = document.getElementById('pickList')
  useEffect(() => {
    const isNotFirst =
      options.map((option) => option.value).indexOf(selectedValue) !== 0
    if (selectedValue && isNotFirst) {
      const listItem = document.getElementById('option' + selectedValue)
      listItem && listItem.scrollIntoView()
    }
  }, [options])
  if (pickListNode) {
    pickListNode.style.top = coords.top + 'px'
    pickListNode.style.left = coords.left + 'px'
    if (coords.width) {
      pickListNode.style.width = coords.width + 'px'
    }
  }

  return (
    <ul className={styles.pickList} id={'pickListComponent'}>
      {options.map((option) => (
        <li
          onClick={() => handleItemClick(option.value)}
          key={option.value}
          id={'option' + option.value}
          className={cx({ [styles.selected]: selectedValue === option.value })}
        >
          {option.label}
        </li>
      ))}
    </ul>
  )
}

export default PickList
