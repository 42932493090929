import { representationByPrivateIndividual } from '../../defaultStates'
import {
  SET_EMPTY_PRIVATE,
  SET_PRIVATE_INDIVIDUAL,
  SET_UPDATE_COMMERCIALLY_ACTIVE,
  SET_UPDATE_PRIVATE_INDIVIDUAL,
} from '../../ActionTypes'

export default (state = representationByPrivateIndividual, action = {}) => {
  switch (action.type) {
    case SET_PRIVATE_INDIVIDUAL: {
      const { key, value, index, subKey, count, edit } = action.payload

      if (key === 'individualDates') {
        const updatedIndividualDates = [...state.individualDates]

        if (typeof index === 'number') {
          if (subKey === 'availableDates') {
            updatedIndividualDates[index] = {
              ...updatedIndividualDates[index],
              availableDates: {
                ...updatedIndividualDates[index]?.availableDates,
                ...value,
              },
            }
          } else if (subKey === 'durationOfAbsence') {
            updatedIndividualDates[index] = {
              availableDates: updatedIndividualDates[index]?.availableDates,
              durationOfAbsence: value,
            }
          }
        } else {
          updatedIndividualDates[index] = {
            ...updatedIndividualDates[index],
            [key]: value,
          }
        }

        !edit &&
          (updatedIndividualDates[index]?.durationOfAbsence ||
            updatedIndividualDates[index]?.durationOfAbsence === '-') &&
          count &&
          updatedIndividualDates.length !== count &&
          updatedIndividualDates.push({
            availableDates: {
              date: '',
              duration: '',
              amount: '',
            },
            durationOfAbsence: '',
          })

        return {
          ...state,
          individualDates: updatedIndividualDates,
        }
      }

      // Update other keys in the state
      return {
        ...state,
        [key]: value,
      }
    }
    case SET_UPDATE_PRIVATE_INDIVIDUAL:
      return {
        ...action.representationByPrivateIndividual,
      }
    case SET_EMPTY_PRIVATE:
      return {
        ...action.privat,
      }
    default:
      return state
  }
}
