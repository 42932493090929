import React, { useCallback } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '../../../constant/constants'
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import styles from './payment.module.scss'
import { createClientSecret } from '../../../api/paymentApi'
import { useNavigate } from 'react-router-dom'
import Logo from '../../../icons/logo.svg'
import ArrowBack from '../../../icons/arrowBack.svg'
import { setChallengeStep } from '../../../redux/actions/challengeStaps'

const stripePromise = loadStripe(STRIPE_KEY)
const Payment = () => {
  const email = useSelector((state) => state.userDetails).email
  const cardType = useSelector((state) => state.quizQuestions).chooseCard
  const reimbursementRef = useSelector((state) => state.payment).reimbursementRef
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fetchClientSecret = useCallback(() => {
    const requestBody = {
      reimbursementRef,
      email,
      option: cardType === 'premium' ? 3 : 2,
      // localMode: true,
    }
    return createClientSecret(requestBody)
      .then((data) => data.token)
      .catch((err) => console.log(err))
  }, [])

  const handleBackClick = () => {
    navigate('/')
    dispatch(setChallengeStep('startChallenge', { challengeStep: 2 }))
  }

  const options = { fetchClientSecret }

  return (
    <div className={styles.payment}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <div className={styles.backSection}>
          <img src={ArrowBack} alt="ArrowBack" onClick={handleBackClick} />
          <img src={Logo} alt="Logo" />
        </div>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default Payment
