import axios from 'axios'
import { devUrl } from '../constant/envVariables'
import { refreshToken } from './loginApi'
import store from '../redux/store'
import logOut from '../redux/thunks/account/logOut'

export const apiPost = (url, body, header) => {
  return axios
    .post(devUrl + url, header ? body : JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        ...header,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        return refreshToken(localStorage.getItem('refreshToken'))
          .then((data) => localStorage.setItem('accessToken', data.accessToken))
          .then(() => apiPost(url, body))
      } else if (
        err.response.data &&
        err.response.data.errorCode === 'USER_REFRESH_TOKEN_EXPIRED'
      ) {
        store.dispatch(logOut())
      } else throw err.response.data || err.response || err
    })
    .then((data) => data.content || data)
}

export const apiPut = (url, body, header) => {
  return axios
    .put(devUrl + url, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        return refreshToken(localStorage.getItem('refreshToken'))
          .then((data) => localStorage.setItem('accessToken', data.accessToken))
          .then(() => apiPut(url, body))
      } else throw err.response.data || err.response || err
    })
    .then((data) => data && (data.content || data))
}

export const apiGet = (url) => {
  return axios
    .get(devUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        return refreshToken(localStorage.getItem('refreshToken'))
          .then((data) => localStorage.setItem('accessToken', data.accessToken))
          .then(() => apiGet(url))
      } else throw err.response.data || err.response || err
    })
    .then((data) =>
      url.includes('api/v1/customers/me/orders?statuses')
        ? data
        : data || data.content,
    )
}

export const apiPatch = (url, body) => {
  return axios
    .patch(devUrl + url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        return refreshToken(localStorage.getItem('refreshToken'))
          .then((data) => localStorage.setItem('accessToken', data.accessToken))
          .then(() => apiPatch(url))
      } else throw err.response.data || err.response || err
    })
    .then((data) => data.content || data)
}

export const apiDelete = (url) => {
  return axios
    .delete(devUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        return refreshToken(localStorage.getItem('refreshToken'))
          .then((data) => localStorage.setItem('accessToken', data.accessToken))
          .then(() => apiDelete(url))
      } else throw err.response.data || err.response || err
    })
    .then((data) => data.content || data)
}
