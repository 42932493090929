import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { completePaymentMethod } from '../../../../api/paymentApi'
import { submitReimbursement } from '../../../../api/formApi'
import SetPassword from '../../../NestedComponents/Option 2/SetPassword'
import { getReimbursements } from '../../../../api/userApi'
import { setUserEmail } from '../../../../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { setReimbursementRef } from '../../../../redux/actions/payment'
import { setChooseCard } from '../../../../redux/actions/quizQuestions'

const PaymentResult = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)
  const refFromParams = queryParams.get('ref')
  const [status, setStatus] = useState(null)
  const reimbursementRef = refFromParams
  const navigate = useNavigate()
  useEffect(() => {
    completePaymentMethod({ reimbursementRef })
      .then(() => {
        getReimbursements(reimbursementRef).then((data) => {
          setStatus('OK')
          dispatch(setUserEmail(data.email))
          dispatch(setReimbursementRef(data.ref))
          dispatch(setChooseCard(data.option))
          const requestBody = {
            ref: data.ref,
            email: data.email,
            option: data.option,
          }
          submitReimbursement(requestBody)
            .then(() => {})
            .catch((err) => console.log(err))
        })
      })
      .catch(() => navigate('/payment'))
  }, [])

  return status && <SetPassword />
}

export default PaymentResult
