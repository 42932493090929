import {
  EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
  SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
  SET_NAME_FORM_VALIDATION,
  EMPTY_NAME_FORM_VALIDATION,
  SET_PASSWORD_FORM_VALIDATION,
  EMPTY_PASSWORD_FORM_VALIDATION,
} from '../../ActionTypes'

export const setSignInWithEmailFormValidation = (field, message) => ({
  type: SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
  field,
  message,
})

export const emptySignInWithEmailFormValidation = () => ({
  type: EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
})

export const setNameFormValidation = (field, message) => ({
  type: SET_NAME_FORM_VALIDATION,
  field,
  message,
})

export const emptyNameFormValidation = () => ({
  type: EMPTY_NAME_FORM_VALIDATION,
})
export const setPasswordFormValidation = (field, message) => ({
  type: SET_PASSWORD_FORM_VALIDATION,
  field,
  message,
})

export const emptyPasswordFormValidation = () => ({
  type: EMPTY_PASSWORD_FORM_VALIDATION,
})
