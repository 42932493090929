import React, { useEffect } from 'react'
import styles from '../resetPasswordSteps.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  emptySignInWithEmailFormValidation,
  setSignInWithEmailFormValidation,
} from '../../../../../../redux/actions/validation'
import { Link } from 'react-router-dom'
import ErrorMessageComponent from '../../../../../ReusableField/ErrorMessageComponent'
import ArrowRight from '../../../../../../icons/arrowRight.svg'
import PageComponent from '../../../../PageComponent'
import Robbot from '../../../../../../icons/robbot.svg'
import Avatar from '../../../../../../icons/avatar.svg'
import emailValidator from '../../../../../../utils/validators/emailValidator'
import Input from '../../../../../UiKitComponents/Input'
import cx from 'classnames'
import Button from '../../../../../UiKitComponents/Button'

const EnterEmail = ({
  email,
  setEmail,
  submitUserEmail,
  errorMessage,
  setErrorMessage,
  disabled,
}) => {
  const dispatch = useDispatch()
  const {
    validation: {
      signInWithEmailFormValidation: { emailErr },
    },
    auth: {
      resetpassword: { step },
    },
  } = useSelector((state) => state)

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [email, emailErr])

  useEffect(() => {
    return () => {
      dispatch(emptySignInWithEmailFormValidation())
    }
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitEmail()
    }
  }

  const validationEmail = (field, value) => {
    let inValid = false
    if (!value) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(field + 'Err', 'E-Mail ist erforderlich'),
      )
    } else if (!emailValidator(value)) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(
          field + 'Err',
          'Falsche E-Mail oder Passwort',
        ),
      )
    }
    return inValid
  }
  const handleChangeEmail = (field, value) => {
    dispatch(setSignInWithEmailFormValidation(field + 'Err', null))
    setEmail(value)
  }
  const handleBlurEmailField = (field, value, e) => {
    if (e?.relatedTarget?.type != 'submit') {
      setErrorMessage('')
      validationEmail(field, value)
    }
  }
  const handleSubmitEmail = () => {
    if (validationEmail('email', email)) {
      return
    }
    submitUserEmail()
  }

  return (
    <PageComponent>
      <div className={styles.forgotPassword}>
        <div>
          <div>
            <div className={styles.challenge}>
              <p>Passwort vergessen?</p>
              <p>
                Kein Problem! Bitte geben Sie die E-Mail-Adresse ein, mit der Sie
                sich registriert haben. Wir senden Ihnen einen sechsstelligen Code
                per E-Mail, mit dem Sie Ihr Passwort zurücksetzen können.
              </p>
            </div>
            <div
              className={cx(styles.form, { [styles.enterEmailForm]: step === 0 })}
            >
              <Input
                type="email"
                placeholder={'E-Mail'}
                value={email}
                onChange={(e) => handleChangeEmail('email', e.currentTarget.value)}
                onBlur={(e) =>
                  handleBlurEmailField('email', e.currentTarget.value, e)
                }
                validationMessage={emailErr}
                maxLength="250"
                required={true}
              />
              {errorMessage && <ErrorMessageComponent errorMessage={errorMessage} />}
              <button
                onClick={handleSubmitEmail}
                disabled={disabled}
                className={cx({
                  [styles.disabled]: disabled,
                })}
              >
                Weiter <img src={ArrowRight} alt={ArrowRight} />
              </button>
              <Link to="/">
                <Button linkBtn>Zurück zum Login</Button>
              </Link>
            </div>
          </div>
          <div className={styles.robbot}>
            <img src={Robbot} alt={Robbot} />
          </div>
        </div>
        <div>
          <div className={styles.contactPerson}>
            <p>You can also contact us</p>
            <div>
              <div>
                <img src={Avatar} alt={Avatar} />
              </div>
              <div>
                <p>Contact Person</p>
                <p>Contact person as title</p>
                <p>support@flexxi.care</p>
                <p>+49 1234 56789</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageComponent>
  )
}

export default EnterEmail
