import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment/moment'
import { setIndividual } from '../../../../redux/actions/careReceipients'
import { setPrivateIndividual } from '../../../../redux/actions/quizQuestions'
import CalendarIcon from '../../../../icons/calendar.svg'
import Calendar from 'react-calendar'
import styles from '../../../Pages/Common/Footer/footer.module.scss'
import RightArrow from '../../../../icons/rightArrowIcon.svg'
import LeftArrow from '../../../../icons/leftArrowIcon.svg'
import ArrowBtn from '../../../../icons/arrowBtn.svg'
import cx from 'classnames'
import Logo from '../../../../icons/miniLogo.svg'
import Time from '../../../../icons/time.svg'
import Amount from '../../../../icons/amount.svg'
import { listEntryRepresentationByPrivateIndividual } from '../../../../constants/quizConstant'
const QuizPrivateIndividual = ({
  parentState,
  setParentState,
  currentIndexIndividual,
}) => {
  const {
    privateIndividual: { quizPrivate, indexPrivate },
    availableDays,
  } = useSelector((state) => state.quizQuestions)
  const {
    representationByPrivateIndividual,
    authorizedPerson: { periodOfAbsenceFrom, periodOfAbsenceTo },
    representativePerson,
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [question, setQuestion] = useState('')
  const [duration, setDuration] = useState('')
  const [amount, setAmount] = useState('')
  const [showCalendar, setShowCalendar] = useState(false)
  const wrapper = useRef(null)
  const [dates, setDates] = useState([])
  let notObjIndex = indexPrivate - availableDays
  let removedItem = indexPrivate - availableDays

  useEffect(() => {
    document.addEventListener('click', handleClickOut, false)
    return () => {
      document.removeEventListener('click', handleClickOut, false)
    }
  }, [])
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  // Function to generate the date range iteratively to avoid misordering
  const generateDateRange = (start, end) => {
    const startDate = new Date(start.split('.').reverse().join('-')) // Convert 'DD.MM.YYYY' to 'YYYY-MM-DD'
    const endDate = new Date(end.split('.').reverse().join('-'))
    const dateArray = []

    let currentDate = startDate
    while (currentDate <= endDate) {
      dateArray.push(formatDate(currentDate)) // Add formatted date
      currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
    }

    return dateArray
  }

  // Generate the date interval when the component mounts
  useEffect(() => {
    const startDate = convertToDate(periodOfAbsenceFrom)
    const endDate = convertToDate(periodOfAbsenceTo)
    const generatedDates = generateDateRange(startDate, endDate)
    setDates(generatedDates) // Set the generated dates in state
  }, [periodOfAbsenceFrom, periodOfAbsenceTo])
  const handleClickOut = (event) => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      setShowCalendar(false)
    }
  }
  const handleChangeAnswer = (e, itemQuestion) => {
    setParentState(e.currentTarget.value)
    setQuestion(itemQuestion)
  }
  const handleChangeDuration = (e) => {
    if (e.currentTarget.value <= 99) {
      setDuration(e.currentTarget.value.replace(/^(\d*\.\d{0,1}).*$/, '$1'))
    }
  }
  const handleChangeAmount = (e) => {
    setAmount(e.currentTarget.value)
  }
  const convertMinutesToHours = (duration) => {
    const hours = Math.ceil(duration / 60)
    return hours
  }
  const keysToRemove = [
    'representingPersonByTransport',
    'representingPersonByCar',
    'zipCodeFrom',
    'zipCodeTo',
  ]

  const filterObject = (obj, keysToExclude) => {
    return Object.keys(obj)
      .filter((key) => !keysToExclude.includes(key)) // Exclude specified keys
      .reduce((acc, key) => {
        acc[key] = obj[key] // Add remaining keys to the new object
        return acc
      }, {})
  }
  const filteredRepresentation = filterObject(
    representationByPrivateIndividual,
    keysToRemove,
  )
  const fliteredIndividual =
    (representativePerson.relationshipList === 'nicht verwandt' ||
      representativePerson.relationshipList === 'andere Verwandtschaft') &&
    (representativePerson.relationshipByMarriage ===
      'Nicht verheiratet oder verschwägert' ||
      representativePerson.relationshipByMarriage ===
        'Anders verwandt oder verschwägert') &&
    representationByPrivateIndividual.individualDates.length === availableDays
      ? filteredRepresentation
      : representationByPrivateIndividual

  const handlePrivateAnswer = (question) => {
    const newQuiz = quizPrivate.map((item, i) => {
      if (i === indexPrivate) {
        return {
          ...item,
          answers:
            typeof item.answers === 'object'
              ? {
                  date: dates[currentIndexIndividual],
                  duration: duration ? `${duration} Std.` : '0 Std.',
                  amount: amount ? `€ ${amount}` : '€ 0',
                }
              : parentState,
        }
      }
      return item
    })

    if (
      availableDays &&
      representationByPrivateIndividual.individualDates.length !== availableDays &&
      (representationByPrivateIndividual.individualDates[
        representationByPrivateIndividual.individualDates.length - 1
      ].availableDates?.amount ||
        representationByPrivateIndividual.individualDates[
          representationByPrivateIndividual.individualDates.length - 1
        ].availableDates?.amount === '0')
    ) {
      newQuiz.splice(
        indexPrivate + 1,
        0,
        {
          answers: {
            date: '',
            duration: '',
            amount: '',
          },
          img: Logo,
          type: 'group',
          required: false,
          questionKey: 'availableDates',
        },
        {
          question: 'War die abwesende Pflegeperson den ganzen Tag verhindert ?',
          // Select box (Less than 8 hours (default), More than 8 hours)
          answers: '',
          img: Logo,
          type: 'submit',
          required: false,
          questionKey: 'durationOfAbsence',
        },
      )
    }

    !representationByPrivateIndividual.individualDates[
      representationByPrivateIndividual.individualDates.length - 1
    ].availableDates.amount ||
    !representationByPrivateIndividual.individualDates[
      representationByPrivateIndividual.individualDates.length - 1
    ].availableDates.duration ||
    !representationByPrivateIndividual.individualDates[
      representationByPrivateIndividual.individualDates.length - 1
    ].durationOfAbsence
      ? dispatch(
          setIndividual(
            Object.keys(representationByPrivateIndividual)[0],
            representationByPrivateIndividual.individualDates[
              representationByPrivateIndividual.individualDates.length - 1
            ].availableDates.amount
              ? parentState
              : {
                  date: dates[currentIndexIndividual],
                  duration: duration ? `${duration} Std.` : '0 Std.',
                  amount: amount ? `€ ${amount}` : '€ 0',
                },
            representationByPrivateIndividual.individualDates.length - 1,
            representationByPrivateIndividual.individualDates[
              representationByPrivateIndividual.individualDates.length - 1
            ].availableDates.amount
              ? 'durationOfAbsence'
              : 'availableDates',
            availableDays,
          ),
        )
      : dispatch(
          setIndividual(
            Object.keys(fliteredIndividual)[
              representativePerson.personRepresenting === 'Nein' &&
              representativePerson.commerciallyActive === 'Ja'
                ? removedItem +
                  4 -
                  (representationByPrivateIndividual.individualDates.length - 1)
                : representationByPrivateIndividual.representingPersonByTransport ===
                    'Nein'
                  ? removedItem +
                    3 -
                    (representationByPrivateIndividual.individualDates.length - 1)
                  : notObjIndex -
                    (representationByPrivateIndividual.individualDates.length - 1)
            ],
            parentState,
            null,
            null,
          ),
        )

    dispatch(setPrivateIndividual(newQuiz, indexPrivate + 1))
    if (
      indexPrivate ===
        2 * representationByPrivateIndividual.individualDates.length &&
      parentState === 'Nein'
    ) {
      const tupdatedQuestions = [
        ...newQuiz.slice(
          0,
          2 * representationByPrivateIndividual.individualDates.length + 1,
        ),
        ...listEntryRepresentationByPrivateIndividual.slice(6),
      ]
      dispatch(
        setPrivateIndividual(
          tupdatedQuestions,
          representationByPrivateIndividual.individualDates.length === 1
            ? 3
            : 2 * representationByPrivateIndividual.individualDates.length + 1,
        ),
      )
    }
    setParentState('')
    setAmount('')
    setDuration('')
  }

  const handleChangePrivateCalendar = (e) => {
    console.log(e)
    setParentState(moment(e).format('DD.MM.YYYY'))
    setShowCalendar(false)
  }
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('.')
    const date = new Date(year, month - 1, day)
    return date.toString()
  }
  const handleShowPrivateCalendar = () => {
    if (!showCalendar) {
      setShowCalendar(true)
    } else {
      setShowCalendar(false)
    }
  }
  return (
    <>
      {quizPrivate?.map((item, i) => {
        if (i === indexPrivate) {
          switch (item?.type) {
            case 'group':
              return (
                <div className={styles.group}>
                  <div>
                    <input
                      placeholder="Datum"
                      value={dates[currentIndexIndividual]}
                      readOnly={true}
                    />
                    <img
                      src={CalendarIcon}
                      alt={CalendarIcon}
                      onClick={handleShowPrivateCalendar}
                    />
                    {/*{!!showCalendar && <Calendar*/}
                    {/*    onChange={(e) => handleChangePrivateCalendar(e)}*/}
                    {/*    // value={!answerActive && periodOfAbsenceFrom ? new Date(convertToDate(periodOfAbsenceFrom)) : !answerActive && periodOfAbsenceFrom === '-' ?*/}
                    {/*    //     null*/}
                    {/*    //     : convertToDate(answerActive)}*/}
                    {/*    minDate={periodOfAbsenceFrom ? new Date(convertToDate(periodOfAbsenceFrom)) : null}*/}
                    {/*    maxDate={periodOfAbsenceTo ? new Date(convertToDate(periodOfAbsenceTo)) : null}*/}
                    {/*    className={styles.reactCalendar}*/}
                    {/*    nextLabel={<img src={RightArrow} alt={'next'}/>}*/}
                    {/*    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}*/}
                    {/*    next2Label={null}*/}
                    {/*    prev2Label={null}*/}
                    {/*    locale={'de'}*/}
                    {/*/>}*/}
                  </div>
                  <div>
                    <input
                      required
                      placeholder={'Dauer/Std.'}
                      value={duration}
                      type={'number'}
                      step="0.1"
                      pattern=""
                      // onInput={(e) =>
                      //   (e.currentTarget.value = e.currentTarget.value.slice(0, 2))
                      // }

                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handlePrivateAnswer()
                      }}
                      key={i}
                      onChange={(e) => handleChangeDuration(e)}
                      autoFocus={true}
                    />
                    <img src={Time} alt={Time} />
                  </div>

                  <div>
                    <input
                      type={'number'}
                      pattern=""
                      placeholder={'Betrag'}
                      value={amount}
                      onChange={(e) => handleChangeAmount(e)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleChangeAmount(e)
                      }}
                      key={i}
                      autoFocus={true}
                    />
                    <img src={Amount} alt={Amount} />
                  </div>
                </div>
              )

            case 'submit':
              return (
                <span>
                  <input
                    type={'submit'}
                    value={'Ja'}
                    onClick={(e) => handleChangeAnswer(e, item.question)}
                    key={i}
                    className={styles.care}
                  />
                  <input
                    type={'submit'}
                    value={'Nein'}
                    onClick={(e) => handleChangeAnswer(e, item.question)}
                    key={i}
                    className={styles.care}
                    autoFocus={true}
                  />
                </span>
              )

            case 'number':
              return (
                <div>
                  <input
                    type={'number'}
                    pattern=""
                    placeholder={'Geben Sie eine Antwort ein.'}
                    value={parentState}
                    onChange={(e) => handleChangeAnswer(e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handlePrivateAnswer()
                    }}
                    key={i}
                    autoFocus={true}
                  />
                  {/*<img src={*/}
                  {/*    item.question === 'Respite care 1: Remuneration' || item.question === 'Respite care 2: Remuneration' ?*/}
                  {/*        Euro : KM*/}
                  {/*} alt={Euro} />*/}
                </div>
              )

            case 'text':
              return (
                <input
                  type={'text'}
                  pattern=""
                  placeholder={'Geben Sie eine Antwort ein.'}
                  value={parentState}
                  onChange={(e) => handleChangeAnswer(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handlePrivateAnswer()
                  }}
                  key={i}
                  autoFocus={true}
                />
              )
            case 'date':
              return (
                <div>
                  <input
                    placeholder="Type date"
                    value={
                      !parentState
                        ? moment(
                            new Date(
                              moment().year(),
                              moment().month(),
                              moment().date(),
                            ),
                          ).format('DD.MM.YYYY')
                        : moment(convertToDate(parentState)).format('DD.MM.YYYY')
                    }
                    required
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handlePrivateAnswer()
                    }}
                    key={i}
                    autoFocus={true}
                    onClick={handleShowPrivateCalendar}
                  />
                  <img
                    src={CalendarIcon}
                    alt={CalendarIcon}
                    onClick={handleShowPrivateCalendar}
                  />
                  {!!showCalendar && (
                    <Calendar
                      onChange={(e) => handleChangePrivateCalendar(e)}
                      value={
                        !parentState
                          ? new Date(
                              moment().year(),
                              moment().month(),
                              moment().date(),
                            )
                          : convertToDate(parentState)
                      }
                      className={styles.reactCalendar}
                      nextLabel={<img src={RightArrow} alt={'next'} />}
                      prevLabel={<img src={LeftArrow} alt={'Previous'} />}
                      next2Label={null}
                      prev2Label={null}
                      locale={'de'}
                    />
                  )}
                </div>
              )
          }
        }
      })}
      <button
        disabled={
          (question === 'Möchten Sie ein anderes Datum hinzufügen?' &&
            !parentState) ||
          (!parentState && !duration && !amount)
        }
        className={cx({
          [styles.btnDisabled]:
            (question === 'Möchten Sie ein anderes Datum hinzufügen?' &&
              !parentState) ||
            (!parentState && !duration && !amount),
        })}
        onClick={() => handlePrivateAnswer(question)}
      >
        <img src={ArrowBtn} alt={ArrowBtn} />
      </button>
    </>
  )
}
export default QuizPrivateIndividual

// let addObject = Object.entries(representationByPrivateIndividual).filter((item)=>typeof item[1] === 'object')
// let addString = Object.entries(representationByPrivateIndividual).filter((item)=>typeof item[1] === 'string')
// const newObj = Object.fromEntries([...addObject, ...addString])
// console.log(newObj);
