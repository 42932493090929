import {
  updateActiveQuiz,
  updateAuthQuiz,
  updateCareQuiz,
  updateLegalQuiz,
  updatePrivateQuiz,
  updateRepQuiz,
} from '../../redux/actions/updateState'

export default () => {
  return (
    window.location.hostname === 'verhinderungspflege.beantragen.flexxi.care' ||
    window.location.hostname === 'www.verhinderungspflege.beantragen.flexxi.care'
  )
}

// export const getData = (groupName, data, questionKey,res, careFileName,legalFileName, setlLegalFileName, setcareFileName ,indexPrivate) => {
//
//     res?.documents && Object.keys(res?.documents).forEach((item)=>{
//         const index = item.indexOf('|')
//         const file = item.slice(index + 1)
//         const fileType = item.slice(0,index)
//         // console.log(fileType);
//         if(fileType === 'RC_LEGAL_REPRESENTATION'){
//             setlLegalFileName(file)
//         }else{
//             setcareFileName(file)
//         }
//     })
//  switch (groupName){
//     case 'quizCare':
//     return {
//         answers: data.careRecipients[questionKey],
//         action : updateCareQuiz,
//         fileName : careFileName
//     }
//     case 'quizLegal':
//      return {
//          answers:  data.legalRepresentations[questionKey],
//          action : updateLegalQuiz,
//          fileName: legalFileName
//      }
//     case 'quizAuth':
//     return {
//         answers:  data.authorizedPerson[questionKey],
//         action: updateAuthQuiz
//     }
//     case 'quizRep':
//      return {
//          answers: data.representativePerson[questionKey],
//          action: updateRepQuiz
//      }
//     case 'quizActive':
//       return {
//           answers: data.representationByCommerciallyActive[questionKey],
//           action: updateActiveQuiz
//       }
//     case 'quizPrivate':
//       return {
//          answers:  data.representationByPrivateIndividual[questionKey],
//           action: updatePrivateQuiz
//       }
//     default : {}
//   }
//
//
// }
// answers: typeof Object.keys(data.representationByPrivateIndividual) === 'object' ?
//     {
//         date: data.representationByPrivateIndividual.availableDates.date,
//         duration:data.representationByPrivateIndividual.availableDates.duration,
//         amount: data.representationByPrivateIndividual.availableDates.amount,
//     } : data.representationByPrivateIndividual[questionKey],
