import React, { useState, useEffect } from 'react'
import styles from '../resetPasswordSteps.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  emptyPasswordFormValidation,
  setPasswordFormValidation,
} from '../../../../../../redux/actions/validation'
import ErrorMessageComponent from '../../../../../ReusableField/ErrorMessageComponent'
import ArrowRight from '../../../../../../icons/arrowRight.svg'
import PageComponent from '../../../../PageComponent'
import Robbot from '../../../../../../icons/robbot.svg'
import Input from '../../../../../UiKitComponents/Input'
import ContactPerson from '../../../../Common/ContactPerson'
import { changePassword, login } from '../../../../../../api/loginApi'
import {
  REIMBURSEMENT_ACCOUNT_NOT_FOUND,
  REIMBURSEMENT_LOGIN_WRONG_CREDENTIALS,
} from '../../../../../../constants/responseStatuses'
import cx from 'classnames'

const SetNewPassword = ({ email, token }) => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const {
    validation: {
      passwordFormValidation: { newPasswordErr, repeatPasswordErr },
    },
    auth: {
      resetpassword: { step },
    },
  } = useSelector((state) => state)
  const [passwordsData, setPasswordsData] = useState({
    newPassword: '',
    repeatPassword: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    passwordsData.newPassword,
    passwordsData.repeatPassword,
    newPasswordErr,
    repeatPasswordErr,
  ])

  useEffect(() => {
    return () => {
      dispatch(emptyPasswordFormValidation())
    }
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitNewPassword()
    }
  }

  const validationPassword = (field, value) => {
    let inValid = false
    if (!value.trim()) {
      inValid = true
      dispatch(setPasswordFormValidation(field + 'Err', 'Passwort ist erforderlich'))
    } else if (value.trim().length < 8) {
      inValid = true
      dispatch(
        setPasswordFormValidation(field + 'Err', 'Falsche E-Mail oder Passwort'),
      )
    } else if (
      passwordsData.newPassword !== passwordsData.repeatPassword &&
      passwordsData.newPassword.length >= 8 &&
      passwordsData.repeatPassword.length >= 8
    ) {
      inValid = true
      dispatch(
        setPasswordFormValidation(
          'newPasswordErr',
          'Die Passwörter stimmen nicht überein',
        ),
      )
      dispatch(
        setPasswordFormValidation(
          'repeatPasswordErr',
          'Die Passwörter stimmen nicht überein',
        ),
      )
    } else if (
      passwordsData.newPassword === passwordsData.repeatPassword &&
      passwordsData.newPassword.length >= 8 &&
      passwordsData.repeatPassword.length >= 8
    ) {
      inValid = false
      dispatch(setPasswordFormValidation('newPasswordErr', null))
      dispatch(setPasswordFormValidation('repeatPasswordErr', null))
    }
    return inValid
  }

  const handleChangePassword = (field, value) => {
    dispatch(setPasswordFormValidation(field + 'Err', null))
    setPasswordsData({ ...passwordsData, [field]: value })
  }

  const handleBlurPasswordField = (field, value) => {
    setErrorMessage('')
    validationPassword(field, value)
  }

  const handleSubmitNewPassword = () => {
    let inValid = false
    if (validationPassword('newPassword', passwordsData.newPassword)) {
      inValid = true
    }
    if (validationPassword('repeatPassword', passwordsData.repeatPassword)) {
      inValid = true
    }
    if (inValid) {
      return
    }
    const requestObj = { password: passwordsData.newPassword, email, token }
    setDisabled(true)
    changePassword(requestObj)
      .then(() => {
        login({ password: passwordsData.newPassword, email })
          .then((data) => {
            localStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('refreshToken', data.refreshToken)
            navigate('/profile')
          })
          .catch((err) => {
            if (err.errorCode === REIMBURSEMENT_LOGIN_WRONG_CREDENTIALS) {
              setErrorMessage('Wrong credentials')
              return
            }
            if (err.errorCode === REIMBURSEMENT_ACCOUNT_NOT_FOUND) {
              setErrorMessage('Reimbursement account not found')
              return
            }
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setDisabled(false))
  }

  return (
    <PageComponent>
      <div className={styles.forgotPassword}>
        <div>
          <div>
            <div className={styles.challenge}>
              <p>Passwort vergessen?</p>
              <p>
                Bitte geben Sie Ihr neues Passwort ein und bestätigen Sie es, indem
                Sie es erneut eingeben. Achten Sie darauf, ein sicheres Passwort zu
                wählen.
              </p>
            </div>
            <div
              className={cx(styles.form, { [styles.newPasswordForm]: step === 2 })}
            >
              <Input
                type={'password'}
                placeholder={'Neues Passwort'}
                value={passwordsData.newPassword}
                onChange={(e) =>
                  handleChangePassword('newPassword', e.currentTarget.value)
                }
                onBlur={(e) =>
                  handleBlurPasswordField('newPassword', e.currentTarget.value)
                }
                validationMessage={newPasswordErr}
                required={true}
                showEye={true}
              />
              <Input
                type={'password'}
                placeholder={'Passwort bestätigen'}
                value={passwordsData.repeatPassword}
                onChange={(e) =>
                  handleChangePassword('repeatPassword', e.currentTarget.value)
                }
                onBlur={(e) =>
                  handleBlurPasswordField('repeatPassword', e.currentTarget.value)
                }
                validationMessage={repeatPasswordErr}
                required={true}
                showEye={true}
              />
              {errorMessage && <ErrorMessageComponent errorMessage={errorMessage} />}
              <button
                onClick={handleSubmitNewPassword}
                disabled={disabled}
                className={cx({
                  [styles.disabled]: disabled,
                })}
              >
                Abschicken
                <img src={ArrowRight} alt={ArrowRight} />
              </button>
            </div>
          </div>
          <div className={styles.robbot}>
            <img src={Robbot} alt={Robbot} />
          </div>
        </div>
        <div>
          <ContactPerson />
        </div>
      </div>
    </PageComponent>
  )
}

export default SetNewPassword
