import { quizQuestions } from '../../defaultStates'
import {
  SET_AUTHORIZED_QUIZ_QUESTIONS,
  SET_AVAILABLE_DAYS,
  SET_CARE_QUIZ_QUESTIONS,
  SET_CHOOSE_CARD,
  SET_COMMERCIALLY_ACTIVE_QUIZ,
  SET_EDIT_FROM_SUMMARY,
  SET_EDIT_PARAMS,
  SET_EMPTY_QUIZ,
  SET_FORM_EDIT,
  SET_INDEX,
  SET_LEGAL_QUIZ_QUESTIONS,
  SET_PRIVATE_INDIVIDUAL_QUIZ,
  SET_QUESTION_GROUPS_STEP,
  SET_REPRESENTATIVE_QUIZ_QUESTIONS,
  SET_UPDATE_ACTIVE_QUIZ,
  SET_UPDATE_AUTH_QUIZ,
  SET_UPDATE_CARE_QUIZ,
  SET_UPDATE_LEGAL_QUIZ,
  SET_UPDATE_PRIVATE_QUIZ,
  SET_UPDATE_QUIZ,
  SET_UPDATE_REP_QUIZ,
} from '../../ActionTypes'

export default (state = quizQuestions, action = {}) => {
  switch (action.type) {
    case SET_CARE_QUIZ_QUESTIONS:
      return {
        ...state,
        quizCareReceipients: {
          quizCare: action.quizCare,
          indexCare: action.indexCare,
        },
      }
    case SET_LEGAL_QUIZ_QUESTIONS:
      return {
        ...state,
        quizLegalRepresentation: {
          quizLegal: action.quizLegal,
          indexLegal: action.indexLegal,
        },
      }
    case SET_AUTHORIZED_QUIZ_QUESTIONS:
      return {
        ...state,
        quizAuthorizedPerson: {
          quizAuth: action.quizAuth,
          indexAuth: action.indexAuth,
        },
      }
    case SET_REPRESENTATIVE_QUIZ_QUESTIONS:
      return {
        ...state,
        quizRepresentativePerson: {
          quizRep: action.quizRep,
          indexRep: action.indexRep,
        },
      }
    case SET_COMMERCIALLY_ACTIVE_QUIZ:
      return {
        ...state,
        commerciallyActive: {
          quizActive: action.quizActive,
          indexActive: action.indexActive,
        },
      }
    case SET_PRIVATE_INDIVIDUAL_QUIZ:
      return {
        ...state,
        privateIndividual: {
          quizPrivate: action.quizPrivate,
          indexPrivate: action.indexPrivate,
        },
      }
    case SET_UPDATE_QUIZ: {
      return {
        ...action.quiz,
      }
    }
    case SET_CHOOSE_CARD:
      return {
        ...state,
        chooseCard: action.chooseCard,
      }
    case SET_EDIT_FROM_SUMMARY:
      return {
        ...state,
        editSummary: action.editSummary,
      }
    case SET_AVAILABLE_DAYS:
      return {
        ...state,
        availableDays: action.availableDays,
      }
    case SET_UPDATE_CARE_QUIZ:
      return {
        ...state,
        quizCareReceipients: {
          quizCare: action.care,
          indexCare: action.index,
        },
      }
    case SET_UPDATE_LEGAL_QUIZ:
      return {
        ...state,
        quizLegalRepresentation: {
          quizLegal: action.legal,
          indexLegal: action.index,
        },
      }
    case SET_UPDATE_AUTH_QUIZ:
      return {
        ...state,
        quizAuthorizedPerson: {
          quizAuth: action.auth,
          indexAuth: action.index,
        },
      }
    case SET_UPDATE_REP_QUIZ:
      return {
        ...state,
        quizRepresentativePerson: {
          quizRep: action.rep,
          indexRep: action.index,
        },
      }
    case SET_UPDATE_ACTIVE_QUIZ:
      return {
        ...state,
        commerciallyActive: {
          quizActive: action.active,
          indexActive: action.index,
        },
      }
    case SET_UPDATE_PRIVATE_QUIZ:
      return {
        ...state,
        privateIndividual: {
          quizPrivate: action.privat,
          indexPrivate: action.index,
        },
      }
    case SET_INDEX:
      return {
        ...state,
        index: action.index,
      }
    case SET_EMPTY_QUIZ:
      return {
        ...action.quiz,
      }
    case SET_EDIT_PARAMS:
      return {
        ...state,
        ...action.params,
      }
    case SET_FORM_EDIT:
      return {
        ...state,
        formEdit: action.formEdit,
      }
    default:
      return state
  }
}
