import {
  SET_AUTHORIZED_QUIZ_QUESTIONS,
  SET_CARE_QUIZ_QUESTIONS,
  SET_CHOOSE_CARD,
  SET_COMMERCIALLY_ACTIVE,
  SET_COMMERCIALLY_ACTIVE_QUIZ,
  SET_EDIT_FROM_SUMMARY,
  SET_LEGAL_QUIZ_QUESTIONS,
  SET_PRIVATE_INDIVIDUAL,
  SET_PRIVATE_INDIVIDUAL_QUIZ,
  SET_QUESTION_GROUPS_STEP,
  SET_REPRESENTATIVE_QUIZ_QUESTIONS,
  SET_EMPTY_QUIZ,
  SET_EDIT_PARAMS,
  SET_FORM_EDIT,
  SET_AVAILABLE_DAYS,
  SET_UPDATE_QUIZ,
} from '../../ActionTypes'

export const setCareQuizQuestions = (quizCare, indexCare) => ({
  type: SET_CARE_QUIZ_QUESTIONS,
  quizCare,
  indexCare,
})
export const setLegalRepresentation = (quizLegal, indexLegal) => ({
  type: SET_LEGAL_QUIZ_QUESTIONS,
  quizLegal,
  indexLegal,
})
export const setAuthPerson = (quizAuth, indexAuth) => ({
  type: SET_AUTHORIZED_QUIZ_QUESTIONS,
  quizAuth,
  indexAuth,
})
export const setRepresentativePerson = (quizRep, indexRep) => ({
  type: SET_REPRESENTATIVE_QUIZ_QUESTIONS,
  quizRep,
  indexRep,
})
export const setCommerciallyActive = (quizActive, indexActive) => ({
  type: SET_COMMERCIALLY_ACTIVE_QUIZ,
  quizActive,
  indexActive,
})
export const setPrivateIndividual = (quizPrivate, indexPrivate) => ({
  type: SET_PRIVATE_INDIVIDUAL_QUIZ,
  quizPrivate,
  indexPrivate,
})
export const setQuestionGroupState = (questionGroupStep) => ({
  type: SET_QUESTION_GROUPS_STEP,
  questionGroupStep,
})

export const setChooseCard = (chooseCard) => ({
  type: SET_CHOOSE_CARD,
  chooseCard,
})
export const setAvailableDays = (availableDays) => ({
  type: SET_AVAILABLE_DAYS,
  availableDays,
})
export const setEditFromSummary = (editSummary) => ({
  type: SET_EDIT_FROM_SUMMARY,
  editSummary,
})

export const setEpmptyQuizState = (quiz) => ({
  type: SET_EMPTY_QUIZ,
  quiz,
})

export const setEditParams = (params) => ({
  type: SET_EDIT_PARAMS,
  params,
})

export const setFormEdit = (formEdit) => ({
  type: SET_FORM_EDIT,
  formEdit,
})
export const updateQuiz = (quiz) => ({
  type: SET_UPDATE_QUIZ,
  quiz,
})
