import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../UiKitComponents/Input'
import Button from '../../../UiKitComponents/Button'
import {
  emptyNameFormValidation,
  setNameFormValidation,
} from '../../../../redux/actions/validation'
import fullNameValidator from '../../../../utils/validators/fullNameValidator'
import { upDateUserData } from '../../../../api/userApi'
import { setToastNotification } from '../../../../redux/actions/toastNotification'
import { setUserDetails } from '../../../../redux/actions/authActions'
import ErrorMessageComponent from '../../../ReusableField/ErrorMessageComponent'

const UserDetailsChange = () => {
  const dispatch = useDispatch()
  const {
    validation: {
      nameFormValidation: { firstNameErr, lastNameErr },
    },
    userDetails,
  } = useSelector((state) => state)
  const { firstName, lastName } = userDetails
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [edit, setEdit] = useState('')
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setUserData({ firstName, lastName })
  }, [firstName, lastName])

  useEffect(() => {
    return () => {
      dispatch(emptyNameFormValidation())
    }
  }, [])

  const handleEditClick = (field) => {
    setEdit(field + 'Edit')
  }

  const handleSave = () => {
    // if (firstNameErr || lastNameErr
    //     || (userData.firstName.trim() === firstName && userData.lastName.trim() === lastName)
    //     || !userData.firstName || !userData.lastName){
    //     return
    // }
    let inValid = false
    if (validation('firstName', userData.firstName)) {
      inValid = true
    }
    if (validation('lastName', userData.lastName)) {
      inValid = true
    }
    if (inValid) {
      return
    }
    const body = {
      firstName: userData.firstName.trim(),
      lastName: userData.lastName.trim(),
    }
    setDisabled(true)
    upDateUserData(body)
      .then(() => {
        dispatch(
          setUserDetails({
            ...userDetails,
            ...body,
          }),
        )
        dispatch(
          setToastNotification({
            type: 'success',
            toastTittle: 'Successfully sent',
            toastBody:
              'Interactively monetize corporate alignments and fully tested niche markets.',
          }),
        )
        setEdit('')
      })
      .catch(() => {
        // dispatch(
        //     setToastNotification({
        //         type: 'error',
        //         toastBody: 'Whoops, something went wrong.',
        //     })
        // )
        setErrorMessage('Whoops, something went wrong.')
      })
      .finally(() => setDisabled(false))
  }

  const validation = (field, value) => {
    let inValid = false
    if (!value.trim()) {
      inValid = true
      dispatch(
        setNameFormValidation(
          field + 'Err',
          field === 'firstName' ? 'Name is required' : 'Last name is required',
        ),
      )
    }
    // else if (!fullNameValidator(value)) {
    //     inValid = true
    //     dispatch(setNameFormValidation(field + 'Err', field === 'firstName' ? 'Name is invalid': 'Last name  is invalid'))
    // }
    return inValid
  }
  const handleChange = (field, value) => {
    dispatch(setNameFormValidation(field + 'Err', null))
    setUserData({ ...userData, [field]: value })
  }
  const handleBlur = (field, value) => {
    setErrorMessage('')
    validation(field, value)
  }

  return (
    <div>
      <p>Name ändern</p>
      <Input
        type={'text'}
        placeholder={'Vorname'}
        value={userData.firstName}
        onChange={(e) => handleChange('firstName', e.currentTarget.value)}
        onBlur={(e) => handleBlur('firstName', e.currentTarget.value)}
        validationMessage={firstNameErr}
        required={true}
        readOnly={!(edit === 'firstNameEdit')}
        edit={!(edit === 'firstNameEdit')}
        onEditClick={() => handleEditClick('firstName')}
        autoFocus={edit === 'firstNameEdit'}
      />
      <Input
        type={'text'}
        placeholder={'Nachname'}
        value={userData.lastName}
        onChange={(e) => handleChange('lastName', e.currentTarget.value)}
        onBlur={(e) => handleBlur('lastName', e.currentTarget.value)}
        validationMessage={lastNameErr}
        required={true}
        readOnly={!(edit === 'lastNameEdit')}
        edit={!(edit === 'lastNameEdit')}
        onEditClick={() => handleEditClick('lastName')}
        autoFocus={edit === 'lastNameEdit'}
      />
      {errorMessage && <ErrorMessageComponent errorMessage={errorMessage} />}
      <Button
        primary
        small
        onClick={handleSave}
        disabled={
          !(
            userData.lastName?.trim() !== lastName ||
            userData.firstName?.trim() !== firstName
          ) || disabled
        }
      >
        {'Speichern'}
      </Button>
    </div>
  )
}

export default UserDetailsChange
