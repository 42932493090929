import {
  authorizedQuestions,
  legalQuestions,
  ListEntryRepresentationByCommerciallyActivePerson,
  listEntryRepresentationByPrivateIndividual,
  questions,
  representativeQuestions,
} from '../constants/quizConstant'

export const metadata = {
  selectedLanguage: 'de',
}
export const userDetails = {
  email: '',
}
export const startChallengeSteps = {
  // enterEmail: { registrationStep: 0 },
  startChallenge: { challengeStep: 0 },
}
export const quizQuestions = {
  quizCareReceipients: {
    quizCare: questions,
    indexCare: 0,
  },
  quizLegalRepresentation: {
    quizLegal: legalQuestions,
    indexLegal: 0,
  },
  quizAuthorizedPerson: {
    quizAuth: authorizedQuestions,
    indexAuth: 0,
  },
  quizRepresentativePerson: {
    quizRep: representativeQuestions,
    indexRep: 0,
  },
  commerciallyActive: {
    quizActive: ListEntryRepresentationByCommerciallyActivePerson,
    indexActive: 0,
  },
  privateIndividual: {
    quizPrivate: listEntryRepresentationByPrivateIndividual,
    indexPrivate: 0,
  },
  questionGroupStep: 1,
  chooseCard: '',
  editSummary: '',
  availableDays: '',
  formEdit: false,
}
export const careReceipients = {
  lastName: '',
  name: '',
  birthday: '',
  address: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  insurance: '',
  insuranceNumber: '',
  levelOfCare: '',
  certificate: '',
  legalRepresentation: '',
}
export const legalRepresentations = {
  lastName: '',
  name: '',
  address: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  document: '',
}
export const authorizedPerson = {
  lastName: '',
  name: '',
  birthday: '',
  address: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  professionalCarer: '',
  periodOfCare: '',
  periodOfAbsenceFrom: '',
  periodOfAbsenceTo: '',
  reasonForTheAbsence: '',
}
export const representativePerson = {
  lastName: '',
  name: '',
  address: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  personRepresenting: '',
  commerciallyActive: '',
  relationshipList: '',
  relationshipByMarriage: '',
  sameHousehold: '',
  relationship: '',
}
export const representationByCommerciallyActive = {
  activeDates: [
    {
      availableDates: {
        date: '',
        duration: '',
        amount: '',
      },
      durationOfAbsence: '',
    },
  ],
  NameOfAccountHolder: '',
  IBAN: '',
  bank: '',
  ConsentToTheData: '',
  iAgree: '',
  iHave: '',
  paid: '',
}
export const representationByPrivateIndividual = {
  individualDates: [
    {
      availableDates: {
        date: '',
        duration: '',
        amount: '',
      },
      durationOfAbsence: '',
    },
  ],
  representingPersonByTransport: '',
  representingPersonByCar: '',
  zipCodeFrom: '',
  zipCodeTo: '',
  NameOfAccountHolder: '',
  IBAN: '',
  bank: '',
  ConsentToTheData: '',
  iAgree: '',
  iHave: '',
  paid: '',
}
export const nonActiveBtn = {
  activeBtn: false,
}
export const auth = {
  phoneNumberObj: {
    countryCode: 'DE',
    callingCode: '49',
    number: '',
  },
  verificationCode: '',
}
export const validation = {
  signInWithEmailFormValidation: {},
  nameFormValidation: {},
  passwordFormValidation: {},
}
export const defaultStore = {
  metadata,
  userDetails,
  startChallengeSteps,
  quizQuestions,
  confirmationModal: null,
  careReceipients,
  legalRepresentations,
  authorizedPerson,
  representativePerson,
  representationByCommerciallyActive,
  representationByPrivateIndividual,
  nonActiveBtn,
  validation,
  auth,
}
