import React, { useState, useEffect } from 'react'
import styles from '../modalComponent.module.scss'
import FlexiiLogo from '../../../../../icons/flexiiLogo.svg'
import { handlePhoneNumberFieldChange } from '../../../../../redux/actions/Auth'
import { useDispatch, useSelector } from 'react-redux'
import PhoneNumberField from '../../../../ReusableField/PhoneNumberField'
import Button from '../../../../UiKitComponents/Button'
import { closeConfirmationModal } from '../../../../../redux/actions/confirmationModal'
import {
  setEditFromSummary,
  setLegalRepresentation,
} from '../../../../../redux/actions/quizQuestions'
import { setLegalRepresentations } from '../../../../../redux/actions/careReceipients'
import countryPickerData from '../../../../../constant/countryPickerData'

const LegalModalComponent = ({ item, legalIndex }) => {
  console.log(item)
  const dispatch = useDispatch()
  // const { phoneNumberObj } = useSelector((state) => state.auth)
  const [answer, setAnswer] = useState(item.answers || '')
  const {
    quizLegalRepresentation: { quizLegal, indexLegal },
  } = useSelector((state) => state.quizQuestions)
  const { legalRepresentations } = useSelector((state) => state)
  const [phoneNumberObj, setPhoneNumberData] = useState({})
  const defaultPhoneObj = useSelector((state) => state.auth).phoneNumberObj

  useEffect(() => {
    if (item.type === 'phoneNumber') {
      if (item.answers !== '-') {
        console.log('item.answers', item.answers)
        const mobileArr = item.answers?.slice(1)?.split(' ')

        const countryCode = countryPickerData.find(
          (countryObj) => countryObj.callingCode === mobileArr[0],
        ).key

        setPhoneNumberData({
          callingCode: mobileArr[0],
          number: mobileArr[1],
          countryCode,
        })
      } else {
        setPhoneNumberData(defaultPhoneObj)
      }
    }
  }, [])
  const handleChangePhone = (newPhoneNumberObj) => {
    // dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    setPhoneNumberData(newPhoneNumberObj)
  }
  const handleLegalAnswer = (index) => {
    // if(!answer) return
    const newQuiz = quizLegal.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          // answers: answer,
          answers:
            item.type !== 'phoneNumber'
              ? answer
              : item.type === 'phoneNumber' && (!answer || answer === '-')
                ? '-'
                : `+${phoneNumberObj.callingCode} ` +
                  '' +
                  `${phoneNumberObj.number}`,
        }
      }
      return item
    })
    dispatch(
      setLegalRepresentations(
        Object.keys(legalRepresentations)[index],
        // Object.keys(legalRepresentations)[index] === 'phoneNumber' ? `+${phoneNumberObj.callingCode} `+``+`${answer}` : answer
        Object.keys(legalRepresentations)[index] !== 'phoneNumber'
          ? answer
          : item.type === 'phoneNumber' && (!answer || answer === '-')
            ? '-'
            : `+${phoneNumberObj.callingCode} ` + '' + `${phoneNumberObj.number}`,
      ),
    )
    dispatch(setLegalRepresentation(newQuiz, indexLegal))
    // dispatch(handlePhoneNumberFieldChange({...phoneNumberObj,number:''}))
    setAnswer('')
  }
  return (
    <section className={styles.modalComponent}>
      <p className={styles.odd}>
        <img src={item?.img && FlexiiLogo} alt={FlexiiLogo} />
        <p>{item.question}</p>
      </p>
      {item.type === 'phoneNumber' && (
        <PhoneNumberField
          onChange={handleChangePhone}
          phoneNumberObj={phoneNumberObj}
          setAnswerr={setAnswer}
          value={answer}
        />
      )}
      <div>
        <Button
          small
          primaryOutlined
          onClick={() => {
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Abbrechen'}
        </Button>
        <Button
          primary
          small
          onClick={() => {
            handleLegalAnswer(legalIndex)
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Speichern'}
        </Button>
      </div>
    </section>
  )
}
export default LegalModalComponent
