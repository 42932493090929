import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../UiKitComponents/Input'
import Button from '../../../UiKitComponents/Button'
import {
  emptyPasswordFormValidation,
  setPasswordFormValidation,
} from '../../../../redux/actions/validation'
import { upDateUserPassword } from '../../../../api/userApi'
import { setToastNotification } from '../../../../redux/actions/toastNotification'
import ErrorMessageComponent from '../../../ReusableField/ErrorMessageComponent'

const Settings = () => {
  const dispatch = useDispatch()
  const {
    validation: {
      passwordFormValidation: { passwordErr, newPasswordErr, repeatPasswordErr },
    },
  } = useSelector((state) => state)
  const [passwordEdit, setPasswordEdit] = useState(false)
  const [passwordsData, setPasswordsData] = useState({
    password: null,
    newPassword: '',
    repeatPassword: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(emptyPasswordFormValidation())
    }
  }, [])

  const handleEditClick = () => {
    setPasswordEdit(true)
    setPasswordsData({
      password: '',
      newPassword: '',
      repeatPassword: '',
    })
  }

  const handleSave = () => {
    let inValid = false
    if (validationPassword('password', passwordsData.password)) {
      inValid = true
    }
    if (validationPassword('newPassword', passwordsData.newPassword)) {
      inValid = true
    }
    if (validationPassword('repeatPassword', passwordsData.repeatPassword)) {
      inValid = true
    }
    if (inValid) {
      return
    }
    const body = {
      currentPassword: passwordsData.password,
      newPassword: passwordsData.newPassword,
    }
    setDisabled(true)
    upDateUserPassword(body)
      .then(() => {
        dispatch(
          setToastNotification({
            type: 'success',
            toastTittle: 'Successfully sent',
            toastBody:
              'Interactively monetize corporate alignments and fully tested niche markets.',
          }),
        )
        setPasswordEdit(false)
      })
      .catch((err) => {
        if (err.errorCode === 'REIMBURSEMENT_ACCOUNT_PASSWORD_INCORRECT') {
          setErrorMessage('Das alte Passwort ist falsch.')
        }
      })
      .finally(() => setDisabled(false))
  }

  const validationPassword = (field, value) => {
    let inValid = false
    if (!value.trim()) {
      inValid = true
      dispatch(setPasswordFormValidation(field + 'Err', 'Passwort wird benötigt'))
    } else if (value.trim().length < 8) {
      inValid = true
      dispatch(
        setPasswordFormValidation(field + 'Err', 'Falsche E-Mail oder Passwort'),
      )
    } else if (
      passwordsData.newPassword !== passwordsData.repeatPassword &&
      passwordsData.newPassword.length >= 8 &&
      passwordsData.repeatPassword.length >= 8
    ) {
      inValid = true
      dispatch(
        setPasswordFormValidation(
          'newPasswordErr',
          'Die Passwörter stimmen nicht überein',
        ),
      )
      dispatch(
        setPasswordFormValidation(
          'repeatPasswordErr',
          'Die Passwörter stimmen nicht überein',
        ),
      )
    } else if (
      passwordsData.newPassword === passwordsData.repeatPassword &&
      passwordsData.newPassword.length >= 8 &&
      passwordsData.repeatPassword.length >= 8
    ) {
      inValid = true
      dispatch(setPasswordFormValidation('newPasswordErr', null))
      dispatch(setPasswordFormValidation('repeatPasswordErr', null))
    }
    return inValid
  }

  const handleChange = (field, value) => {
    dispatch(setPasswordFormValidation(field + 'Err', null))
    setPasswordsData({ ...passwordsData, [field]: value })
  }

  const handleBlur = (field, value) => {
    setErrorMessage('')
    validationPassword(field, value)
  }

  return (
    <div>
      <p>Passwort ändern</p>
      <Input
        type={'password'}
        placeholder={'Aktuelles Passwort'}
        defaultValue={'12345678'}
        value={passwordsData.password}
        onChange={(e) => handleChange('password', e.currentTarget.value)}
        onBlur={(e) => handleBlur('password', e.currentTarget.value)}
        validationMessage={passwordErr}
        required={true}
        readOnly={!passwordEdit}
        edit={!passwordEdit}
        onEditClick={handleEditClick}
        autoFocus={passwordEdit}
        showEye={passwordEdit}
        passwordEdit={passwordEdit}
      />
      {passwordEdit && (
        <>
          <Input
            type={'password'}
            placeholder={'Neues Passwort'}
            value={passwordsData.newPassword}
            onChange={(e) => handleChange('newPassword', e.currentTarget.value)}
            onBlur={(e) => handleBlur('newPassword', e.currentTarget.value)}
            validationMessage={newPasswordErr}
            required={true}
            onEditClick={handleEditClick}
            showEye={true}
          />
          <Input
            type={'password'}
            placeholder={'Passwort wiederholen'}
            value={passwordsData.repeatPassword}
            onChange={(e) => handleChange('repeatPassword', e.currentTarget.value)}
            onBlur={(e) => handleBlur('repeatPassword', e.currentTarget.value)}
            validationMessage={repeatPasswordErr}
            required={true}
            onEditClick={handleEditClick}
            showEye={true}
          />
        </>
      )}

      {errorMessage && <ErrorMessageComponent errorMessage={errorMessage} />}
      <Button
        primary
        small
        onClick={handleSave}
        disabled={!passwordEdit || disabled}
      >
        {'Speichern'}
      </Button>
    </div>
  )
}

export default Settings
