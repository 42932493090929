import {
  SET_AUTHORIZED_PERSON,
  SET_CARE_RECEIPIENTS,
  SET_COMMERCIALLY_ACTIVE,
  SET_EMPTY_ACTIVE,
  SET_EMPTY_AUTH,
  SET_EMPTY_CARE,
  SET_EMPTY_LEGAL,
  SET_EMPTY_PRIVATE,
  SET_EMPTY_QUIZ,
  SET_EMPTY_REP,
  SET_LEGAL_REPRESENTATION,
  SET_NOT_ACTIVE_BTN,
  SET_PRIVATE_INDIVIDUAL,
  SET_REPRESENTATIVE_PERSON,
} from '../../ActionTypes'

export const setCareReceipients = (key, value) => ({
  type: SET_CARE_RECEIPIENTS,
  key,
  value,
})

export const setLegalRepresentations = (key, value) => ({
  type: SET_LEGAL_REPRESENTATION,
  key,
  value,
})

export const setAuthorizedPerson = (key, value) => ({
  type: SET_AUTHORIZED_PERSON,
  key,
  value,
})
export const setRepresentative = (key, value) => ({
  type: SET_REPRESENTATIVE_PERSON,
  key,
  value,
})
export const setCommercially = (
  key,
  value,
  index = null,
  subKey = null,
  count = null,
  edit = false,
) => ({
  type: SET_COMMERCIALLY_ACTIVE,
  payload: { key, value, index, subKey, count, edit },
})
export const setIndividual = (
  key,
  value,
  index = null,
  subKey = null,
  count = null,
  edit = false,
) => ({
  type: SET_PRIVATE_INDIVIDUAL,
  payload: { key, value, index, subKey, count, edit },
})
export const setEmptyCare = (care) => ({
  type: SET_EMPTY_CARE,
  care,
})
export const setEmptyLegal = (legal) => ({
  type: SET_EMPTY_LEGAL,
  legal,
})
export const setEmptyAuth = (auth) => ({
  type: SET_EMPTY_AUTH,
  auth,
})
export const setEmptyRep = (rep) => ({
  type: SET_EMPTY_REP,
  rep,
})
export const setEmptyActive = (active) => ({
  type: SET_EMPTY_ACTIVE,
  active,
})
export const setEmptyPrivate = (privat) => ({
  type: SET_EMPTY_PRIVATE,
  privat,
})
export const setNotActiveBtn = (activeBtn) => ({
  type: SET_NOT_ACTIVE_BTN,
  activeBtn,
})
