import { representativePerson } from '../../defaultStates'
import {
  SET_EMPTY_REP,
  SET_REPRESENTATIVE_PERSON,
  SET_UPDATE_PRIVATE_INDIVIDUAL,
  SET_UPDATE_REPRESENTATIVE_PERSON,
} from '../../ActionTypes'

export default (state = representativePerson, action = {}) => {
  switch (action.type) {
    case SET_REPRESENTATIVE_PERSON:
      return {
        ...state,
        [action.key]: action.value,
      }
    case SET_UPDATE_REPRESENTATIVE_PERSON:
      return {
        ...action.representativePerson,
      }
    case SET_EMPTY_REP:
      return {
        ...action.rep,
      }
    default:
      return state
  }
}
