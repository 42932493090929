import React, { useEffect, useState } from 'react'
import styles from './startChallenge.module.scss'
import Robbot from '../../../icons/robbot.svg'
import Check from '../../../icons/check.svg'
import ArrowRight from '../../../icons/arrowRight.svg'
import {
  setSignUpSignInResetStep,
  setUserEmail,
} from '../../../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { startAccountRobot, login } from '../../../api/loginApi'
import emailValidator from '../../../utils/validators/emailValidator'
import PageComponent from '../../Pages/PageComponent'
import { Link, useNavigate } from 'react-router-dom'
import {
  emptySignInWithEmailFormValidation,
  setSignInWithEmailFormValidation,
} from '../../../redux/actions/validation'
import Input from '../../UiKitComponents/Input'
import {
  EIMBURSEMENT_ACCOUNT_LOGIN_REQUIRED,
  REIMBURSEMENT_LOGIN_WRONG_CREDENTIALS,
  REIMBURSEMENT_ACCOUNT_NOT_FOUND,
} from '../../../constants/responseStatuses'
import { setReimbursementRef } from '../../../redux/actions/payment'
import ErrorMessageComponent from '../../ReusableField/ErrorMessageComponent'
import ContactPerson from '../../Pages/Common/ContactPerson'
import cx from 'classnames'
import Button from '../../UiKitComponents/Button'
import TypingEffect from '../Option 1/TypingEffect'

const StartChallenge = () => {
  const dispatch = useDispatch()
  const { email } = useSelector((state) => state.userDetails)
  const {
    validation: {
      signInWithEmailFormValidation: { emailErr, passwordErr },
    },
  } = useSelector((state) => state)
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loginByEmailPassword, setLoginByEmailPassword] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const preventBackNavigation = () => {
      window.history.pushState(null, '', window.location.href)
    }
    preventBackNavigation()
    window.onpopstate = preventBackNavigation
    return () => {
      window.onpopstate = null
    }
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleStart()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [email, password, emailErr, passwordErr, loginByEmailPassword])
  useEffect(() => {
    return () => {
      dispatch(emptySignInWithEmailFormValidation())
    }
  }, [])

  const handleStart = () => {
    if (!loginByEmailPassword && validationEmail('email', email)) {
      return
    }
    if (!loginByEmailPassword && !validationEmail('email', email)) {
      const requestBody = {
        email,
        params: {},
      }
      setDisabled(true)
      startAccountRobot(requestBody)
        .then((data) => {
          dispatch(setReimbursementRef(data.ref))
          if (data.email) {
            navigate('/startChallenge')
            // setTimeout(() => {
            //     window.history.replaceState(null, '', '/startChallenge');
            //
            // }, 0);
          }
        })
        .catch((err) => {
          if (err.errorCode === EIMBURSEMENT_ACCOUNT_LOGIN_REQUIRED) {
            setLoginByEmailPassword(true)
          }
        })
        .finally(() => setDisabled(false))
    }
    if (loginByEmailPassword) {
      let inValid = false
      if (validationEmail('email', email)) {
        inValid = true
      }
      if (validationPassword('password', password)) {
        inValid = true
      }
      if (!inValid) {
        const body = {
          email,
          password,
        }
        setDisabled(true)
        login(body)
          .then((data) => {
            localStorage.setItem('accessToken', data.accessToken)
            localStorage.setItem('refreshToken', data.refreshToken)
            navigate('/profile')
          })
          .catch((err) => {
            if (err.errorCode === REIMBURSEMENT_LOGIN_WRONG_CREDENTIALS) {
              setErrorMessage('Wrong credentials')
              return
            }
            if (err.errorCode === REIMBURSEMENT_ACCOUNT_NOT_FOUND) {
              setErrorMessage('Reimbursement account not found')
              return
            }
          })
          .finally(() => setDisabled(false))
      }
    }
  }
  const validationEmail = (field, value) => {
    let inValid = false
    if (!value) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(field + 'Err', 'E-Mail ist erforderlich'),
      )
    } else if (!emailValidator(value)) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(
          field + 'Err',
          'Falsche E-Mail oder Passwort',
        ),
      )
    }
    return inValid
  }
  const handleChangeEmail = (field, value) => {
    dispatch(setSignInWithEmailFormValidation(field + 'Err', null))
    dispatch(setUserEmail(value))
  }
  const handleBlurEmailField = (field, value) => {
    setErrorMessage('')
    validationEmail(field, value)
  }
  const validationPassword = (field, value) => {
    let inValid = false
    if (!value) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(field + 'Err', 'Passwort ist erforderlich'),
      )
    } else if (value.trim().length < 8) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(
          field + 'Err',
          'Falsche E-Mail oder Passwort',
        ),
      )
    }
    return inValid
  }
  const handleBlurPasswordField = (field, value, e) => {
    if (e?.relatedTarget?.type != 'submit') {
      setErrorMessage('')
      validationPassword(field, value)
    }
  }
  const handleChangePassword = (field, value) => {
    dispatch(setSignInWithEmailFormValidation(field + 'Err', null))
    setPassword(value)
  }
  const handleResetPassword = () => {
    dispatch(setSignUpSignInResetStep('resetpassword', { step: 0 }))
  }
  const message =
    'Ich nehme dich Schritt für Schritt mit durch deinen Antrag. Ich bin an deiner Seite – egal, bei welcher der 149 Krankenkassen.'
  return (
    <PageComponent>
      <div className={styles.startChallenge}>
        <div>
          <div>
            <div className={styles.challenge}>
              <p>Antrag auf Verhinderungspflege einfach mit FLEXX-i stellen</p>
              <p>
                Hey, ich bin FLEXX-i, dein Einfach Netter für Verhinderungspflege!
                Mit ein paar Fragen und deinem Antrag sichern wir dir bis zu 2.528 €
                ab Pflegegrad 2. Los geht's!
              </p>
            </div>
            <div className={styles.form}>
              <Input
                type="email"
                placeholder={'Email Adresse'}
                value={email}
                onChange={(e) => handleChangeEmail('email', e.currentTarget.value)}
                onBlur={(e) => handleBlurEmailField('email', e.currentTarget.value)}
                validationMessage={emailErr}
                maxLength="250"
                required={true}
              />
              {loginByEmailPassword && (
                <>
                  <Input
                    type="password"
                    placeholder={'Passwort'}
                    value={password}
                    onChange={(e) =>
                      handleChangePassword('password', e.currentTarget.value)
                    }
                    onBlur={(e) =>
                      handleBlurPasswordField('password', e.currentTarget.value, e)
                    }
                    validationMessage={passwordErr}
                    showEye={true}
                    required={true}
                    autoFocus={true}
                  />
                  <Link to="/resetpassword" onClick={handleResetPassword}>
                    <Button onClick={handleResetPassword} linkBtn>
                      Passwort vergessen
                    </Button>
                  </Link>
                </>
              )}
              {errorMessage && <ErrorMessageComponent errorMessage={errorMessage} />}
              <button
                onClick={handleStart}
                disabled={disabled}
                className={cx({
                  [styles.disabled]: disabled,
                })}
              >
                Lass uns starten!
                <img src={ArrowRight} alt={ArrowRight} />
              </button>
            </div>
          </div>
          <div className={styles.robbot}>
            <img src={Robbot} alt={Robbot} />
            <div className={styles.messageRobot}>
              <p>
                <TypingEffect text={message} speed={50} />
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.check}>
            <div>
              <img src={Check} alt={Check} />
              <p>
                Kostenlos: Nutze FLEXX-i ohne Kosten – alles ist komplett gratis.
              </p>
            </div>
            <div>
              <img src={Check} alt={Check} />
              <p>Intuitive Schritt-für-Schritt-Anleitung</p>
            </div>
            <div>
              <img src={Check} alt={Check} />
              <p>Automatische Anspruchsberechnung</p>
            </div>
          </div>
          <ContactPerson />
        </div>
      </div>
    </PageComponent>
  )
}

export default StartChallenge
