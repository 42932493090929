import { combineReducers } from 'redux'
import metadata from './reducers/metadata'
import userDetails from './reducers/userDetails'
import startChallengeSteps from './reducers/challengeStaps'
import quizQuestions from './reducers/quizQuestions'
import careReceipients from './reducers/careReceipients'
import legalRepresentations from './reducers/legalRepresentations'
import authorizedPerson from './reducers/authorizedPerson'
import representativePerson from './reducers/representativePerson'
import representationByCommerciallyActive from './reducers/representationByCommerciallyActive'
import representationByPrivateIndividual from './reducers/representationByPrivateIndividual'
import nonActiveBtn from './reducers/nonActiveBtn'
import auth from './reducers/Auth'
import confirmationModal from './reducers/confirmationModal'
import toastNotification from './reducers/toastNotification'
import validation from './reducers/validation'
import { defaultStore } from './defaultStates'
import { USER_LOGOUT } from './ActionTypes'
import payment from './reducers/payment'

const appReducer = combineReducers({
  metadata,
  userDetails,
  startChallengeSteps,
  quizQuestions,
  careReceipients,
  legalRepresentations,
  payment,
  authorizedPerson,
  representativePerson,
  representationByCommerciallyActive,
  representationByPrivateIndividual,
  validation,
  toastNotification,
  confirmationModal,
  auth,
  nonActiveBtn,
})

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(defaultStore, action)
  }
  return appReducer(state, action)
}

export default rootReducer
