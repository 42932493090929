import React, { useState } from 'react'
import ArrowBtn from '../../../../icons/arrowBtn.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setLegalRepresentations } from '../../../../redux/actions/careReceipients'
import { setLegalRepresentation } from '../../../../redux/actions/quizQuestions'
import styles from '../../../Pages/Common/Footer/footer.module.scss'
import AttachFile from '../../../../icons/attach.svg'
import cx from 'classnames'
import { uploadFile } from '../../../../api/formApi'
import { handlePhoneNumberFieldChange } from '../../../../redux/actions/Auth'
import PhoneNumberField from '../../../ReusableField/PhoneNumberField'

const QuizLegalRepresentation = ({ parentState, setParentState }) => {
  // const [answer, setAnswer] = useState('')
  const [fileName, setFileName] = useState('')
  const {
    quizLegalRepresentation: { quizLegal, indexLegal },
  } = useSelector((state) => state.quizQuestions)
  const {
    legalRepresentations,
    payment: { reimbursementRef },
  } = useSelector((state) => state)
  const { phoneNumberObj } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    setParentState(e.currentTarget.value)
  }
  // const handleFile = (e) =>  {
  //     const file = e.target.files[0]
  //     let formData = new FormData();
  //     formData.append('file', file);
  //     // const payload = {
  //     //     file: formData
  //     // }
  //     uploadFile(formData,reimbursementRef,'RC_LEGAL_REPRESENTATION')
  //         .then((res)=> {
  //             console.log(res)
  //             setParentState(res.amazonUrl)
  //         })
  //         .catch((res)=> console.log(res))
  //     setFileName(file?.name)
  // }
  const handleFile = (e) => {
    const file = e.target.files[0]
    if (!file) {
      console.log('No file selected')
      return
    }
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf']
    if (!allowedFileTypes.includes(file.type)) {
      console.log('Unsupported file type. Please select a JPEG, PNG, or PDF.')
      return
    }
    const maxSizeInBytes = 5 * 1024 * 1024 // 5MB
    if (file.size > maxSizeInBytes) {
      console.log('File size exceeds 5MB.')
      return
    }
    console.log('Selected file:', file)

    let formData = new FormData()
    formData.append('file', file)
    uploadFile(formData, reimbursementRef, 'RC_LEGAL_REPRESENTATION')
      .then((res) => {
        console.log('Upload success:', res)
        setParentState(res.amazonUrl) // Update the parent state
      })
      .catch((error) => {
        console.error('Upload failed:', error)
      })

    // Update file name in UI
    setFileName(file.name)
  }
  const handleLegalAnswer = () => {
    if (!setParentState) return
    const newQuiz = quizLegal.map((item, i) => {
      if (i === indexLegal) {
        return {
          ...item,
          // answers: parentState,
          answers:
            item.type === 'phoneNumber'
              ? `+${phoneNumberObj.callingCode} ` + '' + `${parentState}`
              : parentState,
          fileName: fileName,
        }
      }
      return item
    })
    dispatch(
      setLegalRepresentations(
        Object.keys(legalRepresentations)[indexLegal],
        Object.keys(legalRepresentations)[indexLegal] === 'phoneNumber'
          ? `+${phoneNumberObj.callingCode} ` + '' + `${parentState}`
          : parentState,
      ),
    )
    dispatch(setLegalRepresentation(newQuiz, indexLegal + 1))
    dispatch(
      handlePhoneNumberFieldChange({
        countryCode: 'DE',
        callingCode: '49',
        number: '',
      }),
    )
    setParentState('')
  }
  const handleChangePhone = (newPhoneNumberObj) => {
    dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
  }
  return (
    <>
      {quizLegal.map((item, i) => {
        if (i === indexLegal) {
          switch (item?.type) {
            case 'text':
              return (
                <input
                  type={'text'}
                  pattern=""
                  placeholder={'Geben Sie eine Antwort ein.'}
                  value={parentState}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleLegalAnswer()
                  }}
                  key={i}
                  autoFocus={true}
                />
              )
            case 'phoneNumber':
              return (
                <PhoneNumberField
                  onChange={handleChangePhone}
                  phoneNumberObj={phoneNumberObj}
                  setAnswerr={setParentState}
                />
              )
            case 'number':
              return (
                <input
                  type={'number'}
                  pattern=""
                  placeholder={'Geben Sie eine Antwort ein.'}
                  value={parentState}
                  onChange={(e) => handleChange(e)}
                  onInput={(e) =>
                    (e.currentTarget.value = e.currentTarget.value.slice(0, 5))
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleLegalAnswer()
                  }}
                  key={i}
                  autoFocus={true}
                />
              )
            case 'file':
              return (
                <>
                  <span className={styles.imageUpload}>
                    <label htmlFor="file-input">
                      <img src={AttachFile} alt="Attach File" />
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      onChange={handleFile}
                      accept="image/jpeg,image/png,.pdf"
                    />
                  </span>
                  <input
                    type="text"
                    placeholder="Attach a file"
                    value={fileName}
                    className={styles.attach}
                    readOnly
                  />
                </>
              )
          }
        }
      })}
      {/*{quizLegal.length === indexLegal && <input type={'text'} />}*/}
      {!legalRepresentations.document && (
        <button
          disabled={!parentState}
          className={cx({ [styles.btnDisabled]: !parentState })}
          onClick={handleLegalAnswer}
        >
          <img src={ArrowBtn} alt={ArrowBtn} />
        </button>
      )}
    </>
  )
}
export default QuizLegalRepresentation
