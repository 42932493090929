import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import ToastNotification from '../../UiKitComponents/ToastNotification'
import ConfirmationModal from '../../ReusableField/ConfirmationModal'
import { calculateAge } from '../../../constant/constants'

const PageComponent = (props) => {
  const { careReceipients, authorizedPerson, representativePerson } = useSelector(
    (state) => state,
  )
  const confirmationModal = useSelector((state) => state.confirmationModal)
  const toastNotification = useSelector((state) => state.toastNotification)
  const { pathname } = window.location
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('.')
    const date = new Date(year, month - 1, day)
    return date.toString()
  }
  const footerNull =
    careReceipients.levelOfCare === 'Nein' ||
    careReceipients.levelOfCare === '1' ||
    authorizedPerson.professionalCarer === 'Ja' ||
    authorizedPerson.periodOfCare === 'Unter 6 monate' ||
    pathname === '/chooseData' ||
    pathname === '/summary' ||
    pathname === '/profile' ||
    pathname === '/set-password' ||
    pathname === '/settings'

  useEffect(() => {}, [careReceipients.levelOfCare])
  return (
    <>
      <Header />
      {confirmationModal && <ConfirmationModal {...confirmationModal} />}
      {props.children}
      {/*{footerNull ? null : <Footer/>}*/}
      {footerNull ? null : <Footer />}
      {toastNotification ? <ToastNotification {...toastNotification} /> : []}
    </>
  )
}

export default PageComponent
