import React, { useEffect, useState } from 'react'
import styles from './summaryDetails.module.scss'
import Details from '../Details'
import Robotik from '../../../../icons/robbot.svg'
import { getAuthUserPdf, getPdf } from '../../../../api/formApi'
import cx from 'classnames'
import { getReimbursements } from '../../../../api/userApi'
import {
  setUpdateAuthorizedPerson,
  setUpdateCareQuizQuestions,
  setUpdateLegalRepresentations,
  setUpdateRepresentationByCommerciallyActive,
  setUpdateRepresentationByPrivateIndividual,
  setUpdateRepresentativePerson,
} from '../../../../redux/actions/updateState'
import { useDispatch, useSelector } from 'react-redux'

import { getData } from '../../../../utils/helpers/isProduction'
import { setToastNotification } from '../../../../redux/actions/toastNotification'
import { updateQuiz } from '../../../../redux/actions/quizQuestions'
const SummaryDetails = () => {
  const dispatch = useDispatch()
  const ref = useSelector((state) => state.payment).reimbursementRef
  const [careFileName, setcareFileName] = useState('')
  const [legalFileName, setlLegalFileName] = useState('')
  const accessToken = localStorage.getItem('accessToken')
  const [getPdfDisabled, setGetPdfDisabled] = useState(false)
  const { chooseCard } = useSelector((state) => state.quizQuestions)
  const handleGetPdf = () => {
    const body = { ref }
    setGetPdfDisabled(true)
    accessToken
      ? getAuthUserPdf(body)
          .then(() => {
            dispatch(
              setToastNotification({
                type: 'success',
                toastTittle: 'Formular erfolgreich gesendet!',
                toastBody: 'Bitte überprüfen Sie Ihre E-Mails.',
              }),
            )
          })
          .catch((err) => console.log(err))
          .finally(() => setGetPdfDisabled(false))
      : getPdf(body)
          .then(() => {
            dispatch(
              setToastNotification({
                type: 'success',
                toastTittle: 'Formular erfolgreich gesendet!',
                toastBody: 'Bitte überprüfen Sie ihre E-Mails.',
              }),
            )
          })
          .catch((err) => console.log(err))
          .finally(() => setGetPdfDisabled(false))
  }
  const { quizQuestions } = useSelector((state) => state)
  useEffect(() => {
    getReimbursements(ref).then((data) => {
      data.documents &&
        Object.keys(data.documents).forEach((key) => {
          if (key.includes('RC_CARE_RECIPIENT')) {
            data.templateParams.quizCareReceipients.quizCare.forEach((el) => {
              if (el.type === 'file') {
                el.answers = data.documents[key]
                data.params.careRecipients.certificate = data.documents[key]
              }
            })
          }
          if (key.includes('RC_LEGAL_REPRESENTATION')) {
            data.templateParams.quizLegalRepresentation.quizLegal.forEach((el) => {
              if (el.type === 'file') {
                el.answers = data.documents[key]
                data.params.legalRepresentations.document = data.documents[key]
              }
            })
          }
        })
      console.log('data.templateParams', data.templateParams)
      dispatch(updateQuiz({ ...data.templateParams, chooseCard: chooseCard }))

      dispatch(
        setUpdateCareQuizQuestions({
          lastName: data.params.careRecipients?.lastName,
          name: data.params.careRecipients?.name,
          birthday: data.params.careRecipients?.birthday,
          address: data.params.careRecipients?.address,
          houseNumber: data.params.careRecipients?.houseNumber,
          zipCode: data.params.careRecipients?.zipCode,
          city: data.params.careRecipients?.city,
          phoneNumber: data.params.careRecipients?.phoneNumber,
          insurance: data.params.careRecipients?.insurance,
          insuranceNumber: data.params.careRecipients?.insuranceNumber,
          levelOfCare: data.params.careRecipients?.levelOfCare,
          certificate: data.params.careRecipients?.certificate,
          legalRepresentation: data.params.careRecipients?.legalRepresentation,
        }),
      )
      dispatch(
        setUpdateLegalRepresentations({
          lastName: data.params.legalRepresentations?.lastName,
          name: data.params.legalRepresentations?.name,
          address: data.params.legalRepresentations?.address,
          houseNumber: data.params.legalRepresentations?.houseNumber,
          zipCode: data.params.legalRepresentations?.zipCode,
          city: data.params.legalRepresentations?.city,
          phoneNumber: data.params.legalRepresentations?.phoneNumber,
          document: data.params.legalRepresentations?.document,
        }),
      )
      dispatch(
        setUpdateAuthorizedPerson({
          lastName: data.params.authorizedPerson?.lastName,
          name: data.params.authorizedPerson?.name,
          birthday: data.params.authorizedPerson?.birthday,
          address: data.params.authorizedPerson?.address,
          houseNumber: data.params.authorizedPerson?.houseNumber,
          zipCode: data.params.authorizedPerson?.zipCode,
          city: data.params.authorizedPerson?.city,
          phoneNumber: data.params.authorizedPerson?.phoneNumber,
          professionalCarer: data.params.authorizedPerson?.professionalCarer,
          periodOfCare: data.params.authorizedPerson?.periodOfCare,
          periodOfAbsenceFrom: data.params.authorizedPerson?.periodOfAbsenceFrom,
          periodOfAbsenceTo: data.params.authorizedPerson?.periodOfAbsenceTo,
          reasonForTheAbsence: data.params.authorizedPerson?.reasonForTheAbsence,
          durationOfAbsence: data.params.authorizedPerson?.durationOfAbsence,
        }),
      )
      dispatch(
        setUpdateRepresentativePerson({
          lastName: data.params.representativePerson?.lastName,
          name: data.params.representativePerson?.name,
          address: data.params.representativePerson?.address,
          houseNumber: data.params.representativePerson?.houseNumber,
          zipCode: data.params.representativePerson?.zipCode,
          city: data.params.representativePerson?.city,
          phoneNumber: data.params.representativePerson?.phoneNumber,
          personRepresenting: data.params.representativePerson?.personRepresenting,
          commerciallyActive: data.params.representativePerson?.commerciallyActive,
          relationshipList: data.params.representativePerson?.relationshipList,
          relationshipByMarriage:
            data.params.representativePerson?.relationshipByMarriage,
          sameHousehold: data.params.representativePerson?.sameHousehold,
          relationship: data.params.representativePerson?.relationship,
        }),
      )
      // [...data.params.representationByCommerciallyActive]
      dispatch(
        setUpdateRepresentationByCommerciallyActive({
          activeDates: data.params.representationByCommerciallyActive.activeDates,
          NameOfAccountHolder:
            data.params.representationByCommerciallyActive?.NameOfAccountHolder,
          IBAN: data.params.representationByCommerciallyActive?.IBAN,
          bank: data.params.representationByCommerciallyActive?.bank,
          ConsentToTheData:
            data.params.representationByCommerciallyActive?.ConsentToTheData,
          iAgree: data.params.representationByCommerciallyActive?.iAgree,
          iHave: data.params.representationByCommerciallyActive?.iHave,
          paid: data.params.representationByCommerciallyActive?.paid,
        }),
      )
      dispatch(
        setUpdateRepresentationByPrivateIndividual({
          individualDates:
            data?.params.representationByPrivateIndividual.individualDates,
          representingPersonByTransport:
            data.params.representationByPrivateIndividual
              ?.representingPersonByTransport,
          representingPersonByCar:
            data.params.representationByPrivateIndividual?.representingPersonByCar,
          zipCodeFrom: data.params.representationByPrivateIndividual?.zipCodeFrom,
          zipCodeTo: data.params.representationByPrivateIndividual?.zipCodeTo,
          NameOfAccountHolder:
            data.params.representationByPrivateIndividual?.NameOfAccountHolder,
          IBAN: data.params.representationByPrivateIndividual?.IBAN,
          bank: data.params.representationByPrivateIndividual?.bank,
          ConsentToTheData:
            data.params.representationByPrivateIndividual?.ConsentToTheData,
          iAgree: data.params.representationByPrivateIndividual?.iAgree,
          iHave: data.params.representationByPrivateIndividual?.iHave,
          paid: data.params.representationByPrivateIndividual?.paid,
        }),
      )
    })
  }, [careFileName, legalFileName])
  return (
    <div className={styles.summary}>
      <Details />
      <div className={styles.getPdf}>
        <div>
          <img src={Robotik} alt={Robotik} />
        </div>
        <div>
          <div>
            <p>Hier können Sie Formular überprüfen.</p>
            <button
              onClick={() => handleGetPdf()}
              className={cx({
                [styles.disabled]: getPdfDisabled,
              })}
            >
              PDF herunterladen
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SummaryDetails
