import { apiGet, apiPost } from './api'

export const createForm = (body) => {
  return apiPost('api/v1/reimbursements', body)
}
export const submitReimbursement = (body) => {
  return apiPost('api/v1/reimbursements/submit', body)
}
export const createAuthUserForm = (body) => {
  return apiPost('api/v1/reimbursement-accounts/me/forms', body)
}
export const submitAuthUserForm = (body) => {
  return apiPost('api/v1/reimbursement-accounts/me/forms/submit', body)
}
export const getForms = () => {
  return apiGet(
    'api/v1/reimbursement-accounts/me/forms?page=0&size=100&sort=createdDate,desc',
  )
}
export const generatePdf = (body) => {
  return apiPost('api/v1/reimbursement-accounts/me/forms/generate-pdf', body)
}
export const getAuthUserPdf = (body) => {
  return apiPost('api/v1/reimbursement-accounts/me/forms/resend-email', body)
}
export const getPdf = (body) => {
  return apiPost('api/v1/reimbursements/resend-email', body)
}
export const uploadFile = (body, ref, type) => {
  const params = `?type=${type}`
  const header = {
    'Content-Type': 'multipart/form-data',
  }
  return apiPost(`api/v1/reimbursements/${ref}/documents${params}`, body, header)
}
