import React, { useEffect, useRef, useState } from 'react'
import styles from '../../../Pages/Common/Footer/footer.module.scss'
import {
  insuranceCompanies,
  otherRelationshipList,
  relationshipByMarriageList,
  relationshipList,
} from '../../../../constants/lists/relationshipList'
import { useDispatch, useSelector } from 'react-redux'
import { setRepresentative } from '../../../../redux/actions/careReceipients'
import {
  setPrivateIndividual,
  setRepresentativePerson,
} from '../../../../redux/actions/quizQuestions'
import ArrowBtn from '../../../../icons/arrowBtn.svg'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import PhoneNumberField from '../../../ReusableField/PhoneNumberField'
import { handlePhoneNumberFieldChange } from '../../../../redux/actions/Auth'
import {
  listEntryRepresentationByPrivateIndividual,
  representativeQuestions,
} from '../../../../constants/quizConstant'

const QuizRepresentative = ({ parentState, setParentState }) => {
  const {
    quizRepresentativePerson: { quizRep, indexRep },
  } = useSelector((state) => state.quizQuestions)
  const { representativePerson } = useSelector((state) => state)
  const { phoneNumberObj } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  // const [answerRep, setAnswerRep] = useState('')
  const [repOption, setRepOption] = useState(false)
  const navigate = useNavigate()
  const wrapperRef = useRef(null)
  const { pathname } = window.location
  const autoUpdateQuiz =
    ((representativePerson.relationshipList === 'nicht verwandt' ||
      representativePerson.relationshipList === 'andere Verwandtschaft') &&
      (representativePerson.relationshipByMarriage ===
        'Nicht verheiratet oder verschwägert' ||
        representativePerson.relationshipByMarriage ===
          'Anders verwandt oder verschwägert')) ||
    (representativePerson.personRepresenting === 'Nein' &&
      representativePerson.commerciallyActive === 'Ja')

  // useEffect(() => {
  //   representativePerson.personRepresenting === 'Nein' &&
  //     dispatch(
  //       setRepresentative(
  //         Object.keys(representativePerson)[
  //           Object.keys(representativePerson).length - 1
  //         ],
  //         '',
  //       ),
  //     )
  // }, [representativePerson.personRepresenting, pathname === '/startChallenge'])
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setRepOption(false)
    }
  }
  useEffect(() => {}, [parentState, representativePerson.commerciallyActive])
  const handleChange = (e) => {
    setParentState(e.currentTarget.value)
  }
  const handleRepAnswer = () => {
    // if( representativePerson.relationship && !location.state) {
    //     navigate('/chooseData')
    //     return;
    // }
    // location.state && ( representativePerson.relationship && location.state.from === '/chooseData') && navigate('/startChallenge')
    if (!parentState) return
    const newQuiz = quizRep.map((item, i) => {
      // if( i === indexRep && item.question === 'Wie oder wo haben Sie die Vertretung gefunden?' && answerRep  && !location.state && !localStorage.getItem('accessToken')) {
      //     navigate('/chooseData')
      // }
      if (i === indexRep) {
        return {
          ...item,
          // answers:  parentState,
          answers:
            item.type === 'phoneNumber'
              ? `+${phoneNumberObj.callingCode} ` + '' + `${parentState}`
              : parentState,
          returnQuestion:
            item.question ===
              'Ist die Vertretung gewerblich als Pflegefachkraft tätig oder sogar eine Pflegefirma ?' &&
            parentState === 'Nein'
              ? 'Vertretende Person ist privat und nur gelegentlich als PflegerIn tätig.'
              : item.question ===
                    'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste.' &&
                  parentState !== 'nicht verwandt'
                ? 'Ersatz von Kosten der Verhinderungspflege ist bei Personen in Verwandschaft, Lebensgemeinschaft und Ehepartnern nur eingeschränkt möglich.'
                : item.question ===
                      'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.' &&
                    parentState !== 'Nicht verheiratet oder verschwägert'
                  ? 'Ersatz von Kosten der Verhinderungspflege ist bei Verschwägerten Personen nur eingeschränkt möglich.'
                  : item.question ===
                        'Leben Vertretung und Antragsteller im selben Haushalt ?' &&
                      parentState === 'Ja'
                    ? 'Ersatz von Kosten der Verhinderungspflege ist bei dauernd im gleichen Haushalt lebenden Personen nur eingeschränkt möglich.'
                    : item.question ===
                          'Wie oder wo haben Sie die Vertretung gefunden?' &&
                        parentState
                      ? 'Bitte teilen Sie mir die Details zu der verrichteten Verhinderungspflege mit.'
                      : '',
        }
      }
      return item
    })

    // const filteredQuiz = (representativePerson.personRepresenting === 'Nein' && parentState === 'Ja')
    //     ? newQuiz.filter((_,i) => i !== 9 && i !== 10 && i !== 11) : representativePerson.personRepresenting === 'Ja' ?
    //       newQuiz.filter((_,i) => i !== 8 && i !== 9 && i !== 10 && i !== 11)
    //     : newQuiz
    // console.log(filteredQuiz);
    dispatch(
      setRepresentative(
        Object.keys(representativePerson)[
          representativePerson.personRepresenting === 'Nein' &&
          representativePerson.commerciallyActive === 'Ja'
            ? indexRep + 3
            : representativePerson.personRepresenting === 'Ja'
              ? indexRep + 4
              : indexRep
        ],
        Object.keys(representativePerson)[indexRep] === 'phoneNumber'
          ? `+${phoneNumberObj.callingCode} ` + '' + `${parentState}`
          : parentState,
      ),
    )
    if (indexRep < 7) {
      dispatch(setRepresentativePerson(newQuiz, indexRep + 1))
    }
    if (parentState === 'Nein' && indexRep === 7) {
      const tupdatedQuestions = [
        ...newQuiz.slice(0, 8),
        ...representativeQuestions.slice(8),
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 8))
    }

    if (representativePerson.personRepresenting === 'Nein' && parentState === 'Ja') {
      dispatch(
        setRepresentativePerson(
          newQuiz.filter((_, i) => i !== 9 && i !== 10 && i !== 11),
          9,
        ),
      )
    }

    if (parentState === 'Ja' && indexRep === 7) {
      console.log(1)
      const tupdatedQuestions = [
        ...newQuiz.slice(0, 8), // Get the first 4 elements
        newQuiz[newQuiz.length - 1], // Get the last element
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 8))
    }
    if (
      representativePerson.personRepresenting === 'Nein' &&
      parentState === 'Nein'
    ) {
      console.log(2)

      const tupdatedQuestions = [
        ...newQuiz.slice(0, 9),
        ...representativeQuestions.slice(9),
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 9))
    }
    if (
      representativePerson.personRepresenting &&
      representativePerson.commerciallyActive &&
      parentState
    ) {
      console.log(3)

      dispatch(setRepresentativePerson(newQuiz, 10))
    }
    if (
      representativePerson.personRepresenting === 'Nein' &&
      representativePerson.commerciallyActive === 'Nein' &&
      parentState
    ) {
      console.log(4)

      dispatch(setRepresentativePerson(newQuiz, indexRep + 1))
    }
    if (representativePerson.personRepresenting === 'Ja' && parentState) {
      console.log(5)
      dispatch(setRepresentativePerson(newQuiz, 9))
    }

    dispatch(
      handlePhoneNumberFieldChange({
        countryCode: 'DE',
        callingCode: '49',
        number: '',
      }),
    )
    autoUpdateQuiz &&
      dispatch(
        setPrivateIndividual(
          listEntryRepresentationByPrivateIndividual.filter(
            (item) =>
              item.questionKey !== 'representingPersonByTransport' &&
              item.questionKey !== 'representingPersonByCar' &&
              item.questionKey !== 'zipCodeFrom' &&
              item.questionKey !== 'zipCodeTo',
          ),
          0,
        ),
      )
    setParentState('')
  }
  const clickRepOption = (e) => {
    if (!repOption) {
      setRepOption(true)
    } else {
      setRepOption(false)
    }
  }
  const clickOptionOfRep = (e) => {
    setParentState(e.target.innerText)
    setRepOption(false)
  }
  const handleChangePhone = (newPhoneNumberObj) => {
    dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
  }
  return (
    <>
      {quizRep?.map((item, i) => {
        if (i === indexRep) {
          switch (item?.type) {
            case 'text':
              return (
                <input
                  type={'text'}
                  pattern=""
                  placeholder={'Geben Sie eine Antwort ein.'}
                  value={parentState}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleRepAnswer()
                  }}
                  key={i}
                  autoFocus={true}
                />
              )
            case 'number':
              return (
                <input
                  type={'number'}
                  pattern=""
                  placeholder={'Geben Sie eine Antwort ein.'}
                  value={parentState}
                  onChange={(e) => handleChange(e)}
                  onInput={(e) =>
                    (e.currentTarget.value = e.currentTarget.value.slice(0, 5))
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleRepAnswer()
                  }}
                  key={i}
                  autoFocus={true}
                />
              )
            case 'phoneNumber':
              return (
                <PhoneNumberField
                  onChange={handleChangePhone}
                  phoneNumberObj={phoneNumberObj}
                  setAnswerr={setParentState}
                />
              )
            case 'option':
              return (
                <div className={styles.optionHid}>
                  <div
                    className={cx(styles.select, {
                      [styles.placeholder]: parentState,
                    })}
                    onClick={clickRepOption}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleRepAnswer()
                    }}
                    key={i}
                    ref={wrapperRef}
                  >
                    {parentState ? parentState : 'Bitte wählen Sie'}
                  </div>
                  {!!repOption && (
                    <div className={styles.option}>
                      {item.question ===
                      'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste.'
                        ? relationshipList.map((item) => {
                            return <div onClick={clickOptionOfRep}>{item}</div>
                          })
                        : item.question ===
                            'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.'
                          ? relationshipByMarriageList.map((item) => {
                              return <div onClick={clickOptionOfRep}>{item}</div>
                            })
                          : otherRelationshipList.map((item) => {
                              return <div onClick={clickOptionOfRep}>{item}</div>
                            })}
                    </div>
                  )}
                </div>
              )
            case 'submit':
              return (
                <span>
                  <input
                    type={'submit'}
                    value={'Ja'}
                    onClick={(e) => handleChange(e)}
                    key={i}
                    className={styles.care}
                    autoFocus={true}
                  />
                  <input
                    type={'submit'}
                    value={'Nein'}
                    onClick={(e) => handleChange(e)}
                    key={i}
                    className={styles.care}
                  />
                </span>
              )
          }
        }
      })}
      {quizRep.length !== indexRep && (
        <button
          disabled={!parentState}
          className={cx({ [styles.btnDisabled]: !parentState })}
          onClick={handleRepAnswer}
        >
          <img src={ArrowBtn} alt={ArrowBtn} />
        </button>
      )}
    </>
  )
}
export default QuizRepresentative
