import React, { useEffect } from 'react'
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from 'classnames'
import CancelBtn from '../../../../icons/cancelBtn.svg'
import AcceptBtn from '../../../../icons/acceptBtn.svg'
import Download from '../../../../icons/download.svg'
import Edit from '../../../../icons/edit.svg'
import {
  setCommercially,
  setIndividual,
} from '../../../../redux/actions/careReceipients'
import { useDispatch, useSelector } from 'react-redux'
import CheckboxChecked from '../../../../icons/checkbox-checked.svg'
import CheckboxUnchecked from '../../../../icons/checkbxo-unchecked.svg'
import TypingEffect from '../TypingEffect'
import { setConfirmationModal } from '../../../../redux/actions/confirmationModal'
import IndividualModalComponent from '../NestedComponentOfModal/IndividualModalComponent'
import { setToastNotification } from '../../../../redux/actions/toastNotification'
const PrivateIndividualChallenge = ({
  indexPrivate,
  quizPrivate,
  notAPrivateAnsweredIndex,
  editList,
  setEdit,
  getTextValue,
  handlePrivateTextAnswer,
  edit,
  handleEdit,
  handleCancel,
  messageRefs,
}) => {
  const {
    representationByPrivateIndividual,
    quizQuestions: { editSummary, groupName, index, availableDays },
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  // let notObjIndex =
  //   indexPrivate - representationByPrivateIndividual.individualDates.length
  // useEffect(() => {
  //   console.log(
  //     notObjIndex,
  //     representationByPrivateIndividual.individualDates.length,
  //   )
  // }, [])
  useEffect(() => {
    quizPrivate.map((item, i) => {
      if (!!item.edit && index === i) {
        ;(item.type === 'date' ||
          item.type === 'submit' ||
          item.questionKey === 'availableDates' ||
          item.questionKey === 'durationOfAbsence') &&
          handleIndividualModal(item, i)
      }
    })
  }, [groupName])

  const handleConstant = () => {
    if (representationByPrivateIndividual.ConsentToTheData === '') {
      dispatch(
        setIndividual(Object.keys(representationByPrivateIndividual)[8], 'checked'),
      )
    } else {
      dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[8], ''))
    }
  }
  const handleIAgree = () => {
    if (representationByPrivateIndividual.iAgree === '') {
      dispatch(
        setIndividual(Object.keys(representationByPrivateIndividual)[9], 'checked'),
      )
    } else {
      dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[9], ''))
    }
  }

  const handleIHave = () => {
    if (representationByPrivateIndividual.iHave === '') {
      dispatch(
        setIndividual(Object.keys(representationByPrivateIndividual)[10], 'checked'),
      )
    } else {
      dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[10], ''))
    }
  }
  const handlepaid = () => {
    if (representationByPrivateIndividual.paid === '') {
      dispatch(
        setIndividual(Object.keys(representationByPrivateIndividual)[11], 'checked'),
      )
    } else {
      dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[11], ''))
    }
  }
  const handleIndividualModal = (item, i) => {
    dispatch(
      setConfirmationModal({
        headerText: 'Edit',
        body: <IndividualModalComponent item={item} individualIndex={i} />,
        large: true,
      }),
    )
  }
  return (
    <>
      {quizPrivate.slice(0, notAPrivateAnsweredIndex + 1).map((item, i) => {
        return (
          <>
            {item.question && (
              <li
                key={item.questionKey}
                className={cx(styles.odd)}
                ref={(el) =>
                  (messageRefs.current['representationByPrivateIndividual' + i] = el)
                }
              >
                {item.type === 'constant' ? (
                  <p className={styles.checkbox}>
                    <img
                      className={styles.checkboxStyles}
                      src={
                        representationByPrivateIndividual.ConsentToTheData
                          ? CheckboxChecked
                          : CheckboxUnchecked
                      }
                      alt={'Select'}
                      onClick={() => handleConstant(i)}
                    />
                    {item.answers ||
                    representationByPrivateIndividual.ConsentToTheData ? (
                      item.question
                    ) : (
                      <TypingEffect text={item?.question} speed={50} />
                    )}
                  </p>
                ) : item.type === 'iAgree' ? (
                  representationByPrivateIndividual.ConsentToTheData ===
                    'checked' && (
                    <p className={styles.checkbox}>
                      <img
                        className={styles.checkboxStyles}
                        src={
                          representationByPrivateIndividual.iAgree
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                        alt={'Select'}
                        onClick={() => handleIAgree(i)}
                      />
                      {item.answers || representationByPrivateIndividual.iAgree ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  )
                ) : item.type === 'iHave' ? (
                  representationByPrivateIndividual.iAgree === 'checked' &&
                  representationByPrivateIndividual.ConsentToTheData ===
                    'checked' && (
                    <p className={styles.checkbox}>
                      <img
                        className={styles.checkboxStyles}
                        src={
                          representationByPrivateIndividual.iHave
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                        alt={'Select'}
                        onClick={() => handleIHave(i)}
                      />
                      {item.answers || representationByPrivateIndividual.iHave ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  )
                ) : item.type === 'paid' ? (
                  representationByPrivateIndividual.iAgree === 'checked' &&
                  representationByPrivateIndividual.ConsentToTheData === 'checked' &&
                  representationByPrivateIndividual.iHave === 'checked' && (
                    <p className={styles.checkbox}>
                      <img
                        className={styles.checkboxStyles}
                        src={
                          representationByPrivateIndividual.paid
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                        alt={'Select'}
                        onClick={() => handlepaid(i)}
                      />
                      {item.answers || representationByPrivateIndividual.paid ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  )
                ) : (
                  <>
                    <img src={item?.img} alt={item?.img} />
                    <p>
                      {typeof item.answers === 'object' &&
                      item.answers.date &&
                      item.answers ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  </>
                )}
              </li>
            )}

            {((typeof item.answers === 'object' && item.answers.date) ||
              (typeof item.answers !== 'object' && item.answers)) && (
              <li
                className={cx(styles.even, {
                  [styles.fileStyle]: item?.type === 'file',
                  [styles.hideList]: editList !== 1,
                  [styles.borderLi]:
                    !!item.edit && (item.type === 'number' || item.type === 'text'),
                })}
                onMouseOver={() => setEdit(i)}
                onMouseOut={() => setEdit(-1)}
              >
                {!!item.edit && (item.type === 'text' || item.type === 'number') ? (
                  <>
                    <textarea
                      maxLength={`${item.type === 'number' && 5}`}
                      onChange={(e) => {
                        item.type === 'number' && !Number(e.currentTarget.value)
                          ? dispatch(
                              setToastNotification({
                                type: 'robotik',
                                // toastTittle: 'Successfully sent',
                                toastBody:
                                  'Hey! there is an error! You need to provide only numbers',
                              }),
                            )
                          : getTextValue(e)
                      }}
                      autoFocus={true}
                    >
                      {item?.answers}
                    </textarea>
                    <div>
                      <img
                        src={CancelBtn}
                        alt={CancelBtn}
                        onClick={() => handleCancel(i)}
                      />
                      <img
                        src={AcceptBtn}
                        alt={AcceptBtn}
                        onClick={() => {
                          handlePrivateTextAnswer(i, item.questionKey)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>
                    {/*<p>{item.answers}</p>*/}
                    {typeof item.answers !== 'object' ? (
                      <p>{item.answers}</p>
                    ) : (
                      <p>
                        {item.answers?.date && (
                          <p>
                            <span>Datum:</span> <span>{item.answers?.date}</span>
                          </p>
                        )}
                        {item.answers?.duration && (
                          <p>
                            <span>Dauer:</span>
                            <span>{item.answers?.duration}</span>
                          </p>
                        )}
                        {item.answers?.amount && (
                          <p>
                            <span>Betrag:</span>
                            <span>{item.answers?.amount}</span>
                          </p>
                        )}
                      </p>
                    )}

                    {item?.type === 'file' && <img src={Download} alt={Download} />}
                    {edit === i && !editSummary && (
                      <img
                        src={Edit}
                        alt={Edit}
                        onClick={() =>
                          item.type === 'date' ||
                          item.type === 'submit' ||
                          item.type === 'group'
                            ? handleIndividualModal(item, i)
                            : handleEdit(i)
                        }
                      />
                    )}
                  </p>
                )}
              </li>
            )}
          </>
        )
      })}
    </>
  )
}
export default PrivateIndividualChallenge
