import React, { useState, useEffect } from 'react'
import styles from './setPassword.module.scss'
import Robbot from '../../../../icons/robbot.svg'
import PageComponent from '../../../Pages/PageComponent'
import cx from 'classnames'
import { creatAccount } from '../../../../api/loginApi'
import { useNavigate } from 'react-router-dom'
import Input from '../../../UiKitComponents/Input'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../../api/userApi'
import { setUserDetails } from '../../../../redux/actions/authActions'
import {
  emptySignInWithEmailFormValidation,
  setSignInWithEmailFormValidation,
} from '../../../../redux/actions/validation'

const SetPassword = () => {
  const email = useSelector((state) => state.userDetails).email
  const ref = useSelector((state) => state.payment).reimbursementRef
  const [password, setPassword] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    validation: {
      signInWithEmailFormValidation: { passwordErr },
    },
  } = useSelector((state) => state)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSetPassword()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [email, password, passwordErr])

  useEffect(() => {
    return () => {
      dispatch(emptySignInWithEmailFormValidation())
    }
  }, [])

  const handleSetPassword = () => {
    if (validationPassword('password', password)) {
      return
    }
    let requestBody = {
      reimbursementRef: ref,
      password: password,
    }
    setDisabled(true)
    creatAccount(requestBody)
      .then((data) => {
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)
        getUserData()
          .then((data) => {
            dispatch(setUserDetails(data))
          })
          .catch((err) => console.log(err))
        navigate('/summary')
      })
      .catch(() => {
        setErrorMessage(
          'Whoops, something went wrong. Please contact our support team',
        )
        return
      })
      .finally(() => setDisabled(false))
  }
  const validationPassword = (field, value) => {
    let inValid = false
    if (!value) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(
          field + 'Err',
          'Erstellen Sie jetzt Ihr Passwort',
        ),
      )
    } else if (value.trim().length < 8) {
      inValid = true
      dispatch(
        setSignInWithEmailFormValidation(
          field + 'Err',
          'Falsche E-Mail oder Passwort',
        ),
      )
    }
    return inValid
  }
  const handleBlurPasswordField = (field, value) => {
    setErrorMessage('')
    validationPassword(field, value)
  }
  const handleChangePassword = (field, value) => {
    dispatch(setSignInWithEmailFormValidation(field + 'Err', null))
    setPassword(value)
  }

  return (
    <PageComponent>
      <div className={styles.setPassword}>
        <div>
          <h1>Erstellen Sie jetzt Ihr Passwort</h1>
          <p>Bitte legen Sie hier ein Passwort fest, bevor wir loslegen.</p>
          <div
            className={cx(styles.form, {
              [styles.disable]: email,
            })}
          >
            <Input
              placeholder={'E-Mail'}
              value={email}
              disabled={!!email}
              required={true}
            />
            <Input
              type={'password'}
              placeholder={'Passwort'}
              value={password}
              onChange={(e) =>
                handleChangePassword('password', e.currentTarget.value)
              }
              onBlur={(e) =>
                handleBlurPasswordField('password', e.currentTarget.value)
              }
              validationMessage={passwordErr}
              required={true}
              showEye={true}
            />
            {/*<Input*/}
            {/*    type={'password'}*/}
            {/*    placeholder={'Passwort bestätigen'}*/}
            {/*    value={password}*/}
            {/*    onChange={(e) => handleChangePassword('password', e.currentTarget.value)}*/}
            {/*    onBlur={(e) => handleBlurPasswordField('password', e.currentTarget.value)}*/}
            {/*    validationMessage={passwordVal}*/}
            {/*    required={true}*/}
            {/*    showEye={true}*/}
            {/*/>*/}
            {errorMessage && <span>{errorMessage}</span>}
            <button
              onClick={handleSetPassword}
              disabled={disabled}
              className={cx({
                [styles.disabled]: disabled,
              })}
            >
              Weiter
            </button>
          </div>
        </div>
        <div>
          <img src={Robbot} alt={Robbot} />
        </div>
      </div>
    </PageComponent>
  )
}
export default SetPassword
