import React, { useState, useEffect } from 'react'
import styles from './profile.module.scss'
import Robbot from '../../../../icons/robbot.svg'
import PageComponent from '../../../Pages/PageComponent'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import Arrow from '../../../../icons/arrow.svg'
import {
  getForms,
  getAuthUserPdf,
  createAuthUserForm,
} from '../../../../api/formApi'
import * as moment from 'moment'
import { setReimbursementRef } from '../../../../redux/actions/payment'
import { useDispatch, useSelector } from 'react-redux'
import {
  authorizedPerson,
  careReceipients,
  legalRepresentations,
  quizQuestions,
  representationByCommerciallyActive,
  representationByPrivateIndividual,
  representativePerson,
} from '../../../../redux/defaultStates'
import { setEpmptyQuizState } from '../../../../redux/actions/quizQuestions'
import { setToastNotification } from '../../../../redux/actions/toastNotification'
import {
  setEmptyActive,
  setEmptyAuth,
  setEmptyCare,
  setEmptyLegal,
  setEmptyPrivate,
  setEmptyRep,
} from '../../../../redux/actions/careReceipients'

const Profile = () => {
  const [rotate, setRotate] = useState(false)
  const [formsData, setFormsData] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userDetails)
  const { email } = useSelector((state) => state.userDetails)
  const [getPdfDisabled, setGetPdfDisabled] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)

  useEffect(() => {
    getForms()
      .then((data) => setFormsData(data.content.sort()))
      .catch((err) => console.log(err))
  }, [])

  const handleRotateCare = () => {
    setRotate(!rotate)
  }

  const handleGetPdf = (ref) => {
    const body = { ref }
    setGetPdfDisabled(ref)
    getAuthUserPdf(body)
      .then(() => {
        dispatch(
          setToastNotification({
            type: 'success',
            toastTittle: 'Formular erfolgreich gesendet!',
            toastBody: 'Bitte überprüfen Sie ihre E-Mails.',
          }),
        )
      })
      .catch((err) => console.log(err))
      .finally(() => setGetPdfDisabled(''))
  }

  const handleViewForm = (ref) => {
    dispatch(setReimbursementRef(ref))
    navigate('/summary')
  }
  const handleNewFormStart = () => {
    const requestBody = {
      email,
      params: {},
    }
    setFormDisabled(true)
    createAuthUserForm(requestBody)
      .then((data) => {
        dispatch(setReimbursementRef(data.ref))
        dispatch(setEpmptyQuizState(quizQuestions))
        dispatch(setEmptyRep(representativePerson))
        dispatch(setEmptyActive(representationByCommerciallyActive))
        dispatch(setEmptyAuth(authorizedPerson))
        dispatch(setEmptyPrivate(representationByPrivateIndividual))
        dispatch(setEmptyCare(careReceipients))
        dispatch(setEmptyLegal(legalRepresentations))
        navigate('/startChallenge')
      })
      .catch((err) => console.log(err))
      .finally(() => setFormDisabled(false))
    // dispatch(setEpmptyQuizState(quizQuestions))
    // dispatch(setEmptyRep(representativePerson))
    // dispatch(setEmptyActive(representationByCommerciallyActive))
    // dispatch(setEmptyAuth(authorizedPerson))
    // dispatch(setEmptyPrivate(representationByPrivateIndividual))
    // dispatch(setEmptyCare(careReceipients))
    // dispatch(setEmptyLegal(legalRepresentations))
    // navigate('/startChallenge')
  }
  return (
    <PageComponent>
      <div className={styles.profile}>
        <div className={styles.formInfoWrapper}>
          <div>
            <div>
              <h1>Hallo, {userData.firstName}</h1>
              <p>Hier finden Sie alle Ihre Formulare.</p>
              <button
                onClick={handleNewFormStart}
                disabled={formDisabled}
                className={cx({
                  [styles.disabled]: formDisabled,
                })}
              >
                Ein neues Formular starten
              </button>
            </div>
            <div className={styles.robbot}>
              <img src={Robbot} alt={Robbot} />
            </div>
          </div>

          <div className={styles.forms}>
            <div>
              <p>
                {userData.firstName} {userData.lastName}
              </p>
              <img
                src={Arrow}
                alt={Arrow}
                onClick={handleRotateCare}
                className={cx({
                  [styles.rotate]: rotate,
                })}
              />
            </div>
            {rotate && (
              <ul>
                {formsData?.map((form, i) => {
                  return (
                    <li>
                      <p>{moment(form.date).format('DD.MM.YYYY')}</p>
                      <p>
                        <button
                          onClick={() => handleViewForm(form.reimbursementRef)}
                        >
                          Formular anzeigen
                        </button>
                        <button
                          onClick={() => handleGetPdf(form.reimbursementRef)}
                          disabled={getPdfDisabled === form.reimbursementRef}
                          className={cx({
                            [styles.disabled]:
                              getPdfDisabled === form.reimbursementRef,
                          })}
                        >
                          PDF herunterladen
                        </button>
                      </p>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
        <div className={styles.robbot}>
          <img src={Robbot} alt={Robbot} />
        </div>
      </div>
    </PageComponent>
  )
}
export default Profile
