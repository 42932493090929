import React from 'react'
import styles from './phoneNumberField.module.scss'
import CountryPicker from '../CountryPicker'
import ErrorIcon from '../../../icons/errorIcon.svg'
import { useTranslation } from 'react-i18next'
import { NUMBER_REGEXP } from '../../../constant/constants'
import cx from 'classnames'
import countryPickerData from '../../../constant/countryPickerData'
import isNumber from '../../../utils/validators/isNumber'

const PhoneNumberField = ({
  phoneNumberObj,
  errorMessage,
  onChange,
  setAnswerr,
  value,
}) => {
  const { t } = useTranslation()
  const maxLength = countryPickerData.find(
    (country) => country.key === phoneNumberObj.countryCode,
  )?.maxLength
  const invalidChars = ['e', 'E', '.', ',', '-', '+']
  const handleChangeInputValue = (e) => {
    if (
      (e.currentTarget.value === '' || isNumber(e.currentTarget.value)) &&
      !e.currentTarget.value.includes('e') &&
      !e.currentTarget.value.includes('E')
    ) {
      const newPhoneNumberObj = {
        ...phoneNumberObj,
        number: e.currentTarget.value.slice(0, maxLength),
      }
      onChange(newPhoneNumberObj)
      setAnswerr(e.currentTarget.value)
    }
  }
  const handleKeyDown = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const handleChangeCountryCode = (countryCode, callingCode) => {
    const newPhoneNumberObj = {
      ...phoneNumberObj,
      countryCode: countryCode,
      callingCode: callingCode,
    }
    onChange(newPhoneNumberObj)
  }
  return (
    <div>
      <div
        className={cx(styles.phoneNumberField, {
          [styles.invalidInput]: errorMessage,
        })}
      >
        <CountryPicker
          countryCode={phoneNumberObj.countryCode}
          handleChangeCountryCode={handleChangeCountryCode}
        />
        <span> +{phoneNumberObj.callingCode} </span>
        <input
          placeholder={'12345678'}
          onChange={handleChangeInputValue}
          onKeyDown={handleKeyDown}
          // value={phoneNumberObj.number || value}
          value={phoneNumberObj.number}
          autoFocus
          type={'number'}
        />
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>
          <img src={ErrorIcon} alt={ErrorIcon} />
          <span>{t(errorMessage)}</span>
        </div>
      )}
    </div>
  )
}

export default PhoneNumberField
