import Logo from '../icons/miniLogo.svg'
const questions = [
  {
    question: 'Wie ist der Familienname des pflegebedürftigen Antragstellers ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'lastName',
  },
  {
    question: 'Und wie ist der Vorname?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'name',
  },
  {
    question: 'Dann brauche ich bitte das Geburtsdatum.',
    answers: '',
    img: Logo,
    type: 'date',
    required: false,
    questionKey: 'birthday',
  },
  {
    question: 'Danke! In welcher Strasse wohnt der Pflegebedürftige ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'address',
  },
  {
    question: 'Und wie ist die Hausnummer ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'houseNumber',
  },
  {
    question:
      'Können Sie mir bitte noch die Postleitzahl des Pflegebedürftigen nennen ?',
    answers: '',
    img: Logo,
    type: 'number',
    required: false,
    questionKey: 'zipCode',
  },
  {
    question: 'Und den Ort.',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'city',
  },
  {
    question:
      'Nur wenn Sie möchten, können Sie für Rückfragen eine Telefonnummer angeben.',
    //add here Skip this question button, from click set asw '-'
    answers: '',
    img: Logo,
    type: 'phoneNumber',
    required: false,
    questionKey: 'phoneNumber',
  },
  {
    question:
      'Bei welcher Krankenversicherung ist der Pflegebedürftige ? Bitte wählen Sie aus der Liste aus. Keine Eile, ich trinke solange meinen Espresso.',
    // add into input option selector
    answers: '',
    img: Logo,
    type: 'option',
    required: false,
    questionKey: 'insurance',
  },
  {
    question:
      'Super! Geben Sie mir bitte die Versichertennummer des Pflegebedürftigen.',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'insuranceNumber',
  },
  {
    question:
      'Wurde bereits ein Pflegegrad erteilt ? Wenn ja, welcher ? Bitte wählen Sie aus der Liste aus.',
    //add into input rating submit(1, 2, 3,) btns
    answers: '',
    returnQuestion: '',
    img: Logo,
    type: 'rate',
    required: true,
    questionKey: 'levelOfCare',
  },
  {
    question:
      'Wenn Sie möchten , können Sie eine Kopie des Pflegegradbescheids hochladen.',
    //add into input attach btn
    answers: '',
    img: Logo,
    type: 'file',
    required: false,
    questionKey: 'certificate',
    fileName: '',
  },
  {
    question: 'Steht der Pflegebedürftige unter gesetzlicher Betreuung ?',
    //add into input yes/no btn
    answers: '',
    img: Logo,
    type: 'submit',
    required: true,
    questionKey: 'legalRepresentation',
  },
]

const legalQuestions = [
  {
    question: 'Gut, kein Problem! Wie ist der Familienname des Betreuers ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'lastName',
  },
  {
    question: 'Und wie ist der Vorname?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'name',
  },
  {
    question: 'Danke! In welcher Strasse wohnt oder arbeitet der Betreuer ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'address',
  },
  {
    question: 'Und bitte noch die Hausnummer.',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'houseNumber',
  },
  {
    question: 'Können Sie mir bitte die Postleitzahl des Betreuers nennen ?',
    answers: '',
    img: Logo,
    type: 'number',
    required: false,
    questionKey: 'zipCode',
  },
  {
    question: 'Und den Ort.',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'city',
  },
  {
    question: 'Für Rückfragen geben Sie bitte eine Telefonnummer an.',
    answers: '',
    img: Logo,
    type: 'phoneNumber',
    required: false,
    questionKey: 'phoneNumber',
  },
  {
    question:
      'Wenn Sie möchten , können Sie eine Kopie der Betreuungsverfügung hochladen.',
    answers: '',
    img: Logo,
    type: 'file',
    required: false,
    questionKey: 'document',
    fileName: '',
  },
]
const authorizedQuestions = [
  {
    question:
      'Wie ist der Familienname der Person, die üblicherweise pflegt und verhindert war oder ist ? ',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'lastName',
  },
  {
    question: 'Und der Vorname ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'name',
  },
  {
    question:
      'Dann brauche ich bitte deren Geburtsdatum. Ich weiss, viele Fragen, tut mir leid.',
    answers: '',
    img: Logo,
    type: 'birthDay',
    required: false,
    questionKey: 'birthday',
  },
  {
    question: 'Danke! In welcher Strasse wohnt die Person, die verhindert ist ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'address',
  },
  {
    question: 'Und wie ist die Hausnummer ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'houseNumber',
  },
  {
    question: 'Können Sie mir bitte noch die Postleitzahl nennen ?',
    answers: '',
    img: Logo,
    type: 'number',
    required: false,
    questionKey: 'zipCode',
  },
  {
    question:
      'Und den Ort. Habe ich schon mal gefragt, aber hier geht es nun um die pflegende Person. Muss ich leider fragen, die deutschen Gesetze mal wieder...',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'city',
  },
  {
    question:
      'Nur wenn Sie möchten, können Sie für Rückfragen die Telefonnummer der üblicherweise pflegenden Person angeben.',
    answers: '',
    img: Logo,
    type: 'phoneNumber',
    required: false,
    questionKey: 'phoneNumber',
  },
  {
    question:
      'Wird die verhinderte Pflegeperson vom Pflegebedürftigen als Pfleger bezahlt oder ist sogar eine Pflegefirma ?',
    //add into input yes/no btn
    answers: '',
    returnQuestion: '',
    img: Logo,
    type: 'submit',
    required: true,
    questionKey: 'professionalCarer',
  },
  {
    question:
      'Wie lange hat die verhinderte Pflegeperson schon den Antragsteller gepflegt ?',
    //add into input Less than 6 months/More than 6 months btn
    answers: '',
    img: Logo,
    type: 'submit',
    required: true,
    questionKey: 'periodOfCare',
  },
  {
    question:
      'Ab wann ist die normalerweise pflegende Person verhindert oder verhindert gewesen ?',
    // Calendar (default: current day)
    answers: '',
    img: Logo,
    type: 'date',
    required: true,
    questionKey: 'periodOfAbsenceFrom',
  },
  {
    question: 'Und bis wann ?',
    // Calendar (default: current day)
    answers: '',
    img: Logo,
    type: 'date',
    required: true,
    questionKey: 'periodOfAbsenceTo',
  },
  {
    question:
      'Weshalb ist die Person verhindert ?  Bitte wählen Sie einen Grund aus der Liste aus.',
    // Select box (from the list)
    answers: '',
    img: Logo,
    type: 'option',
    required: false,
    questionKey: 'reasonForTheAbsence',
  },
  // {
  //     question: "War die abwesende Pflegeperson den ganzen Tag verhindert ?",
  //     // Select box (Less than 8 hours (default), More than 8 hours)
  //     answers: '',
  //     img: Logo,
  //     type: 'submit',
  //     required: false,
  //     questionKey: 'durationOfAbsence'
  // },
]
const representativeQuestions = [
  {
    question: 'Wie lautet der Familienname der Vertretung ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'lastName',
  },
  {
    question: 'Und der Vorname ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'name',
  },
  {
    question: 'Danke! In welcher Strasse wohnt die Vertretung ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'address',
  },
  {
    question: 'Und wie ist die Hausnummer ?',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'houseNumber',
  },
  {
    question: 'Können Sie mir bitte noch die Postleitzahl nennen ?',
    answers: '',
    img: Logo,
    type: 'number',
    required: false,
    questionKey: 'zipCode',
  },
  {
    question: 'Und den Ort. Sie wissen ja, deutsche Gesetze...',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'city',
  },
  {
    question:
      'Nur wenn Sie möchten, können Sie für Rückfragen die Telefonnummer der Vertretung angeben.',
    answers: '',
    img: Logo,
    type: 'phoneNumber',
    required: false,
    questionKey: 'phoneNumber',
  },
  {
    question:
      'Steht die Vertretung neben dem Antragsteller noch anderen Personen als Pfleger gegen Bezahlung zur Verfügung, z.B. auch als pflegerischer Angestellter bei einem Pflegedienst?',
    //add into input yes/no btn  questions 56
    answers: '',
    returnQuestion: '',
    img: Logo,
    type: 'submit',
    required: true,
    questionKey: 'personRepresenting',
  },
  {
    question:
      'Ist die Vertretung gewerblich als Pflegefachkraft tätig oder sogar eine Pflegefirma ?',
    //add into input yes/no btn questions 57
    answers: '',
    returnQuestion: '',
    img: Logo,
    type: 'submit',
    required: true,
    questionKey: 'commerciallyActive',
  },
  {
    question:
      'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste.',
    //select list questions 58
    answers: '',
    img: Logo,
    type: 'option',
    required: true,
    questionKey: 'relationshipList',
  },
  {
    question:
      'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.',
    //select list questions 59
    answers: '',
    img: Logo,
    type: 'option',
    required: true,
    questionKey: 'relationshipByMarriage',
  },
  {
    question: 'Leben Vertretung und Antragsteller im selben Haushalt ?',
    //add into input yes/no btn questions 60
    answers: '',
    img: Logo,
    type: 'submit',
    required: true,
    questionKey: 'sameHousehold',
  },
  {
    question: 'Wie oder wo haben Sie die Vertretung gefunden?',
    //select list
    answers: '',
    img: Logo,
    type: 'option',
    required: true,
    questionKey: 'relationship',
  },
]
// IF 56 and/or 57 YES:
const ListEntryRepresentationByCommerciallyActivePerson = [
  {
    answers: {
      date: '',
      duration: '',
      amount: '',
    },
    img: Logo,
    type: 'group',
    required: false,
    questionKey: 'availableDates',
  },
  {
    question: 'War die abwesende Pflegeperson den ganzen Tag verhindert ?',
    // Select box (Less than 8 hours (default), More than 8 hours)
    answers: '',
    img: Logo,
    type: 'submit',
    required: false,
    questionKey: 'durationOfAbsence',
  },
  {
    question:
      'Wie ist der Name des Kontoinhabers, der die Rückerstattung erhalten soll',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'NameOfAccountHolder',
  },
  {
    question:
      'Und die IBAN? Ich verstehe, wenn Sie diese Daten nicht mit mir teilen wollen. Bitte vergessen Sie nur dann nicht, die Angaben nachher im Ausdruck  nachzutragen.',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'IBAN',
  },
  {
    question: 'Bank',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'bank',
  },
  {
    question: 'Ich stimme der Datenverarbeitung, vor allem nach § 67 III SGB X zu.',
    img: Logo,
    type: 'constant',
    required: true,
    questionKey: 'ConsentToTheData',
  },
  {
    question:
      'Ich stimme zu, dass die Daten in Erfüllung der Aufgaben nach § 94 I Nr. 3 SGB XI verarbeitet werden.' +
      ' Sie sind erforderlich zur Prüfung des Anspruchs auf Verhinderungspflege nach § 39 SGB XI und erfordern die ' +
      'Mitwirkung nach § 60 SGB I. Mir ist bekannt, dass ohne Mitwirkung über den Anspruch nicht entschieden werden kann' +
      ' (§§ 33 I SGB XI, 60 I, 66 I SGB I). Mir ist bekannt, dass ich Allgemeine Informationen zur Datenverarbeitung und zu' +
      ' meinen Rechten von der Krankenkasse erhalten kann, wenn ich dies möchte. Die Angabe meiner Telefonnummer erfolgte ' +
      'freiwillig. Ich stimme zu, falls ich sie gegeben habe, dass ich bei Rückfragen telefonisch erreicht werden darf.',
    img: Logo,
    type: 'iAgree',
    required: true,
    questionKey: 'iAgree',
  },
  {
    question:
      'Ich habe alle Informationen freiwillig unter Zuhilfenahme der Dienste der FLEXXI Care Deutschland GmbH eingetragen ' +
      'und berechtige diese Firma, sie zum Zweck der Datenverarbeitung zu verwenden, insbesondere Ausdrucke für mich zu erstellen.' +
      ' Ich stelle FLEXXI Care von jeder Haftung, auch für fehlerhafte Übermittlung, frei. Ich habe vor Absendung die Verpflichtung,' +
      ' alle Unterlagen zu kontrollieren.',
    img: Logo,
    type: 'iHave',
    required: true,
    questionKey: 'iHave',
  },
  {
    question: 'Ich bestätige, daß der Betrag ausbezahlt wurde.',
    img: Logo,
    type: 'paid',
    required: true,
    questionKey: 'paid',
  },
]
// IF 56, 57, 60 YES, and 58, 59 NOT UNRELATED
const listEntryRepresentationByPrivateIndividual = [
  {
    // Calendar (default: current day)
    answers: {
      date: '',
      duration: '',
      amount: '',
    },
    img: Logo,
    type: 'group',
    required: false,
    questionKey: 'availableDates',
  },
  {
    question: 'War die abwesende Pflegeperson den ganzen Tag verhindert ?',
    // Select box (Less than 8 hours (default), More than 8 hours)
    answers: '',
    img: Logo,
    type: 'submit',
    required: false,
    questionKey: 'durationOfAbsence',
  },
  {
    question: 'Sind Kosten für die Anreise angefallen ?',
    // yes/ no btn
    // If YES, attachment field ????
    answers: '',
    img: Logo,
    type: 'submit',
    required: false,
    questionKey: 'representingPersonByTransport',
  },
  {
    question: 'Wieviele km musste die Vertretung fahren ?',
    // Integer field (show km behind)
    answers: '',
    img: Logo,
    type: 'number',
    required: false,
    questionKey: 'representingPersonByCar',
  },
  {
    question: 'Von Postleitzahl und Stadt',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'zipCodeFrom',
  },
  {
    question: 'Nach Postleitzahl und Stadt',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'zipCodeTo',
  },
  {
    question:
      'Wie ist der Name des Kontoinhabers, der die Rückerstattung erhalten soll',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'NameOfAccountHolder',
  },
  {
    question:
      'Und die IBAN? Ich verstehe, wenn Sie diese Daten nicht mit mir teilen wollen. Bitte vergessen Sie nur dann nicht, die Angaben nachher im Ausdruck  nachzutragen.',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'IBAN',
  },
  {
    question: 'Bank',
    answers: '',
    img: Logo,
    type: 'text',
    required: false,
    questionKey: 'bank',
  },
  {
    question: 'Ich stimme der Datenverarbeitung, vor allem nach § 67 III SGB X zu.',
    img: Logo,
    type: 'constant',
    required: true,
    questionKey: 'ConsentToTheData',
  },
  {
    question:
      'Ich stimme zu, dass die Daten in Erfüllung der Aufgaben nach § 94 I Nr. 3 SGB XI verarbeitet werden.' +
      ' Sie sind erforderlich zur Prüfung des Anspruchs auf Verhinderungspflege nach § 39 SGB XI und erfordern die ' +
      'Mitwirkung nach § 60 SGB I. Mir ist bekannt, dass ohne Mitwirkung über den Anspruch nicht entschieden werden kann' +
      ' (§§ 33 I SGB XI, 60 I, 66 I SGB I). Mir ist bekannt, dass ich Allgemeine Informationen zur Datenverarbeitung und zu' +
      ' meinen Rechten von der Krankenkasse erhalten kann, wenn ich dies möchte. Die Angabe meiner Telefonnummer erfolgte ' +
      'freiwillig. Ich stimme zu, falls ich sie gegeben habe, dass ich bei Rückfragen telefonisch erreicht werden darf.',
    img: Logo,
    type: 'iAgree',
    required: true,
    questionKey: 'iAgree',
  },
  {
    question:
      'Ich habe alle Informationen freiwillig unter Zuhilfenahme der Dienste der FLEXXI Care Deutschland GmbH eingetragen ' +
      'und berechtige diese Firma, sie zum Zweck der Datenverarbeitung zu verwenden, insbesondere Ausdrucke für mich zu erstellen.' +
      ' Ich stelle FLEXXI Care von jeder Haftung, auch für fehlerhafte Übermittlung, frei. Ich habe vor Absendung die Verpflichtung,' +
      ' alle Unterlagen zu kontrollieren.',
    img: Logo,
    type: 'iHave',
    required: true,
    questionKey: 'iHave',
  },
  {
    question: 'Ich bestätige, daß der Betrag ausbezahlt wurde.',
    img: Logo,
    type: 'paid',
    required: true,
    questionKey: 'paid',
  },
]

const finalQuestions = {
  question:
    'Es ist geschafft !!! Danke für Ihre Geduld. Sie bekommen den Antrag nun per E-Mail zugeschickt.',
  img: Logo,
}

export {
  questions,
  legalQuestions,
  authorizedQuestions,
  representativeQuestions,
  ListEntryRepresentationByCommerciallyActivePerson,
  listEntryRepresentationByPrivateIndividual,
  finalQuestions,
}
