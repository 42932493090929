import {
  quizQuestions,
  representationByCommerciallyActive,
} from '../../defaultStates'
import {
  SET_COMMERCIALLY_ACTIVE,
  SET_EMPTY_ACTIVE,
  SET_UPDATE_COMMERCIALLY_ACTIVE,
  SET_UPDATE_LEGAL_REPRESENTATION,
} from '../../ActionTypes'

import store from '../../store'
// const {quizQuestions:{availableDays}} = store.getState()

export default (state = representationByCommerciallyActive, action = {}) => {
  switch (action.type) {
    case SET_COMMERCIALLY_ACTIVE: {
      const { key, value, index, subKey, count, edit } = action.payload

      if (key === 'activeDates') {
        const updatedActiveDates = [...state.activeDates]

        if (typeof index === 'number') {
          if (subKey === 'availableDates') {
            updatedActiveDates[index] = {
              ...updatedActiveDates[index],
              availableDates: {
                ...updatedActiveDates[index]?.availableDates,
                ...value,
              },
            }
          } else if (subKey === 'durationOfAbsence') {
            updatedActiveDates[index] = {
              availableDates: updatedActiveDates[index]?.availableDates,
              durationOfAbsence: value,
            }
          }
        } else {
          updatedActiveDates[index] = {
            ...updatedActiveDates[index],
            [key]: value,
          }
        }

        !edit &&
          (updatedActiveDates[index]?.durationOfAbsence ||
            updatedActiveDates[index]?.durationOfAbsence === '-') &&
          count &&
          updatedActiveDates.length !== count &&
          updatedActiveDates.push({
            availableDates: {
              date: '',
              duration: '',
              amount: '',
            },
            durationOfAbsence: '',
          })

        return {
          ...state,
          activeDates: updatedActiveDates,
        }
      }
      return {
        ...state,
        [key]: value,
      }
    }

    case SET_UPDATE_COMMERCIALLY_ACTIVE:
      return {
        ...action.representationByCommerciallyActive,
      }

    case SET_EMPTY_ACTIVE:
      return {
        ...action.active,
      }
    default:
      return state
  }
}
