import React, { useState } from 'react'
import styles from '../modalComponent.module.scss'
import FlexiiLogo from '../../../../../icons/flexiiLogo.svg'
import Calendar from 'react-calendar'
import moment from 'moment/moment'
import RightArrow from '../../../../../icons/rightArrowIcon.svg'
import LeftArrow from '../../../../../icons/leftArrowIcon.svg'
import Button from '../../../../UiKitComponents/Button'
import { closeConfirmationModal } from '../../../../../redux/actions/confirmationModal'
import { setCommercially } from '../../../../../redux/actions/careReceipients'
import cx from 'classnames'
import {
  setCommerciallyActive,
  setEditFromSummary,
  setFormEdit,
} from '../../../../../redux/actions/quizQuestions'
import { useDispatch, useSelector } from 'react-redux'
import CalendarIcon from '../../../../../icons/calendar.svg'
import Time from '../../../../../icons/time.svg'
import Amount from '../../../../../icons/amount.svg'

const ActiveModalComponent = ({ item, activeIndex }) => {
  // setFormEdit(true)
  const {
    commerciallyActive: { quizActive, indexActive },
    availableDays,
  } = useSelector((state) => state.quizQuestions)
  const {
    representationByCommerciallyActive,
    authorizedPerson: { periodOfAbsenceFrom, periodOfAbsenceTo },
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [answerActive, setAnswerActive] = useState(item.answers.date || '')
  const [duration, setDuration] = useState(
    item.answers?.duration ? parseFloat(item.answers?.duration) : '0' || '',
  )
  const [amount, setAmount] = useState(
    item.answers.amount?.replace(/[^\d]/g, '') || '',
  )
  const [showCalendar, setShowCalendar] = useState(false)
  let notObjIndex =
    indexActive - representationByCommerciallyActive.activeDates.length

  let activeDatesObj = Object.values(
    representationByCommerciallyActive,
  )[0].findIndex(
    (itemOfObj) =>
      itemOfObj.availableDates?.date === item.answers?.date &&
      itemOfObj.availableDates?.duration === item.answers?.duration &&
      itemOfObj.availableDates?.amount === item.answers?.amount,
  )

  let activeDatesAbsence = Object.values(
    representationByCommerciallyActive,
  )[0].findIndex((itemOfObj) => itemOfObj.durationOfAbsence === item.answers)
  const handleChangeActiveCalendar = (e) => {
    setAnswerActive(moment(e).format('DD.MM.YYYY'))
    setShowCalendar(false)
  }
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('.')
    const date = new Date(year, month - 1, day)
    return date.toString()
  }
  const convertMinutesToHours = (duration) => {
    const hours = Math.ceil(duration / 60)
    return hours
  }
  const handleActiveAnswer = (index) => {
    console.log(index)
    const newQuiz = quizActive.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          answers:
            typeof item.answers === 'object'
              ? {
                  ...item.answers,
                  date: answerActive ? answerActive : item.answers.date,
                  duration: duration
                    ? `${duration} Std.`
                    : !duration
                      ? '0 Std.'
                      : item.answers.duration,
                  amount: amount
                    ? `€ ${amount}`
                    : !amount
                      ? '€ 0'
                      : item.answers.duration,
                }
              : answerActive
                ? answerActive
                : item.answers,
          edit: false,
        }
      }
      return item
    })
    item.questionKey === 'durationOfAbsence' || item.questionKey === 'availableDates'
      ? dispatch(
          setCommercially(
            Object.keys(representationByCommerciallyActive)[0],
            amount
              ? {
                  date: answerActive,
                  duration: duration
                    ? `${duration} Std.`
                    : !duration
                      ? '0 Std.'
                      : item.answers.duration,
                  amount: amount
                    ? `€ ${amount}`
                    : !amount
                      ? '€ 0'
                      : item.answers.duration,
                }
              : answerActive
                ? answerActive
                : item.answers,
            typeof item.answers === 'object' ? activeDatesObj : (index + 1) / 2 - 1,
            amount ? 'availableDates' : 'durationOfAbsence',
            availableDays,
            true,
          ),
        )
      : dispatch(
          setCommercially(
            Object.keys(representationByCommerciallyActive)[
              representationByCommerciallyActive.activeDates.length === 1
                ? indexActive
                : notObjIndex - 2
            ],
            answerActive,
            null,
            null,
          ),
        )

    dispatch(setCommerciallyActive(newQuiz, indexActive))
    setAnswerActive('')
  }
  const handleShowActiveCalendar = () => {
    if (!showCalendar) {
      setShowCalendar(true)
    } else {
      setShowCalendar(false)
    }
  }
  const handleChangeDuration = (e) => {
    e.preventDefault()
    if (e.currentTarget.value <= 99) {
      setDuration(e.currentTarget.value.replace(/^(\d*\.\d{0,1}).*$/, '$1'))
    }
  }
  const handleChangeAmount = (e) => {
    setAmount(e.currentTarget.value)
  }
  const handleChange = (e) => {
    setAnswerActive(e.currentTarget.value)
  }
  return (
    <section className={styles.modalComponent}>
      <p className={styles.odd}>
        <img src={item?.img && FlexiiLogo} alt={FlexiiLogo} />
        <p>
          {item.type === 'group' ? 'Details zur verrichteten Pflege' : item.question}
        </p>
      </p>

      {item.type === 'group' && (
        <div className={styles.group}>
          <div>
            <input placeholder="Datum" value={answerActive} readOnly={true} />
            <img
              src={CalendarIcon}
              alt={CalendarIcon}
              onClick={handleShowActiveCalendar}
            />
          </div>
          <div>
            <input
              required
              placeholder={'Dauer'}
              value={duration}
              type={'number'}
              pattern=""
              // onInput={(e) =>
              //   (e.currentTarget.value = e.currentTarget.value.slice(0, 2))
              // }
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleActiveAnswer()
              }}
              onChange={(e) => handleChangeDuration(e)}
              autoFocus={true}
            />
            <img src={Time} alt={Time} />
          </div>

          <div>
            <input
              type={'number'}
              placeholder={'Betrag'}
              value={amount}
              onChange={(e) => handleChangeAmount(e)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleActiveAnswer(e)
              }}
              autoFocus={true}
            />
            <img src={Amount} alt={Amount} />
          </div>
        </div>
      )}
      {item.type === 'submit' && (
        <span>
          {
            <>
              <input
                type={'submit'}
                value={'Ja'}
                onClick={(e) => handleChange(e)}
                className={styles.care}
              />
              <input
                type={'submit'}
                value={'Nein'}
                onClick={(e) => handleChange(e)}
                className={styles.care}
              />
            </>
          }
        </span>
      )}
      <div>
        <Button
          small
          primaryOutlined
          onClick={() => {
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Abbrechen'}
        </Button>
        <Button
          primary
          small
          onClick={() => {
            handleActiveAnswer(activeIndex)
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Speichern'}
        </Button>
      </div>
    </section>
  )
}
export default ActiveModalComponent
