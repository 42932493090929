import React, { useEffect, useRef, useState } from 'react'
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from 'classnames'
import CancelBtn from '../../../../icons/cancelBtn.svg'
import AcceptBtn from '../../../../icons/acceptBtn.svg'
import Download from '../../../../icons/download.svg'
import FlexiiLogo from '../../../../icons/flexiiLogo.svg'
import Edit from '../../../../icons/edit.svg'
import TypingEffect from '../TypingEffect'
import { setCareReceipients } from '../../../../redux/actions/careReceipients'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCareQuizQuestions,
  setEditFromSummary,
} from '../../../../redux/actions/quizQuestions'
import { uploadFile } from '../../../../api/formApi'
import { setConfirmationModal } from '../../../../redux/actions/confirmationModal'
import CareModalComponent from '../NestedComponentOfModal/CareModalComponent/CareModalComponent'
import { setToastNotification } from '../../../../redux/actions/toastNotification'

const CareChallenge = ({
  quizCare,
  notCareAnsweredIndex,
  editList,
  setEdit,
  getTextValue,
  handleCancel,
  handleCareTextAnswer,
  edit,
  handleEdit,
  careReceipients,
  indexCare,
  messageRefs,
}) => {
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  const fileName = quizCare.find((item) => item.type === 'file')?.fileName
  const {
    payment: { reimbursementRef },
    quizQuestions: { index, groupName, editSummary },
  } = useSelector((state) => state)
  const { phoneNumberObj } = useSelector((state) => state.auth)

  useEffect(() => {
    quizCare.map((item, i) => {
      if (!!item.edit && index === i) {
        ;(item.type === 'date' ||
          item.type === 'rate' ||
          item.type === 'option' ||
          item.type === 'submit' ||
          item.type === 'phoneNumber') &&
          handleModalCare(item, i)
      }
      if (!!item.edit && index === i) {
        item.type === 'file' && handleDivClick(i)
      }
    })
  }, [groupName])

  const handleFileChange = async (e, ind) => {
    try {
      // Log index for debugging
      console.log('Index Care:', indexCare)

      const file = e.target.files[0]

      // Validation: Ensure a file is selected
      if (!file) {
        console.error('No file selected')
        alert('Please select a file to upload.')
        return
      }
      // Create FormData and append the file
      let formData = new FormData()
      formData.append('file', file)

      // Call the upload function
      const res = await uploadFile(formData, reimbursementRef, 'RC_CARE_RECIPIENT')

      if (!res || !res.amazonUrl) {
        console.error('File upload failed. Invalid response:', res)
        alert('Failed to upload the file. Please try again.')
        return
      }

      // Update the quizCare state
      const updatedQuizCare = quizCare.map((item, i) => {
        if (i === ind) {
          return {
            ...item,
            answers: res.amazonUrl, // Add uploaded file URL
            fileName: file.name, // Add file name for display
          }
        }
        return item
      })

      // Dispatch the actions
      dispatch(setCareReceipients(Object.keys(careReceipients)[ind], res.amazonUrl)) // Update careReceipients
      dispatch(setCareQuizQuestions(updatedQuizCare, indexCare)) // Update quizCare state
      dispatch(setEditFromSummary(''))
      console.log('File uploaded successfully:', res)
    } catch (error) {
      console.error('Error during file upload:', error)
      alert('An error occurred while uploading the file. Please try again.')
    }
  }

  const handleDivClick = () => {
    // Simulate click on hidden input when div is clicked
    fileInputRef.current.click()
  }
  const downloadFile = (url, name) => {
    if (url === '-') {
      return null
    } else {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}`)
          document.body.appendChild(link)
          link.click()
          link?.parentNode?.removeChild(link)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const handleModalCare = (item, i) => {
    dispatch(
      setConfirmationModal({
        headerText: 'Edit',
        body: <CareModalComponent item={item} careIndex={i} />,
        large: true,
      }),
    )
  }
  return (
    <>
      {quizCare?.slice(0, notCareAnsweredIndex + 1)?.map((item, i) => {
        return (
          // key={Math.random()}
          <>
            <li
              className={styles.odd}
              ref={(el) => (messageRefs.current['careReceipients' + i] = el)}
            >
              <img src={item?.img && FlexiiLogo} alt={FlexiiLogo} />
              <p>
                {item?.answers ? (
                  item.question
                ) : (
                  <TypingEffect text={item?.question} speed={50} />
                )}
              </p>
            </li>
            {item?.answers && (
              <li
                className={cx(styles.even, {
                  [styles.fileStyle]: item?.type === 'file',
                  [styles.hideList]: editList !== 1,
                  [styles.borderLi]:
                    !!item.edit && (item.type === 'text' || item.type === 'number'),
                })}
                onMouseOver={() => setEdit(i)}
                onMouseOut={() => setEdit(-1)}
              >
                {!!item.edit && (item.type === 'text' || item.type === 'number') ? (
                  <>
                    <textarea
                      maxLength={`${item.type === 'number' && 5}`}
                      onChange={(e) => {
                        console.log(e)
                        item.type === 'number' && !Number(e.currentTarget.value)
                          ? dispatch(
                              setToastNotification({
                                type: 'robotik',
                                // toastTittle: 'Successfully sent',
                                toastBody:
                                  'Hey! there is an error! You need to provide only numbers',
                              }),
                            )
                          : getTextValue(e)
                      }}
                      autoFocus={true}
                    >
                      {item?.answers}
                    </textarea>
                    <div>
                      <img
                        src={CancelBtn}
                        alt={CancelBtn}
                        onClick={() => handleCancel(i)}
                      />
                      <img
                        src={AcceptBtn}
                        alt={AcceptBtn}
                        onClick={() => {
                          handleCareTextAnswer(i)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>
                    {item.type === 'file' ? item.fileName : item?.answers}
                    {/*{ item.type === 'phoneNumber' &&  item?.answers}*/}
                    {item?.type === 'file' && (
                      <img
                        src={Download}
                        alt="Download"
                        onClick={() =>
                          downloadFile(careReceipients.certificate, fileName)
                        }
                      />
                    )}
                    {edit === i && !editSummary && (
                      <img
                        src={Edit}
                        alt={Edit}
                        onClick={() =>
                          item.type === 'file'
                            ? handleDivClick(i)
                            : item.type === 'date' ||
                                item.type === 'rate' ||
                                item.type === 'option' ||
                                item.type === 'submit' ||
                                item.type === 'phoneNumber'
                              ? handleModalCare(item, i)
                              : handleEdit(i)
                        }
                      />
                    )}
                    {item?.type === 'file' && (
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, i)}
                        accept="image/jpeg,image/png,.pdf"
                      />
                    )}
                  </p>
                )}
              </li>
            )}
            {item?.returnQuestion && (
              <div className={styles.returnQuestion}>
                {/*<img src={item?.img} alt={item?.img}/>*/}
                <p>{item?.returnQuestion}</p>
              </div>
            )}
          </>
        )
      })}
    </>
  )
}
export default CareChallenge
