import { validation } from '../../defaultStates'
import {
  EMPTY_NAME_FORM_VALIDATION,
  EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
  SET_NAME_FORM_VALIDATION,
  SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION,
  SET_PASSWORD_FORM_VALIDATION,
  EMPTY_PASSWORD_FORM_VALIDATION,
} from '../../ActionTypes'

export default (state = validation, action = {}) => {
  switch (action.type) {
    case SET_SIGNIN_WITH_EMAIL_FORM_VALIDATION:
      return {
        ...state,
        signInWithEmailFormValidation: {
          ...state.signInWithEmailFormValidation,
          [action.field]: action.message,
        },
      }
    case EMPTY_SIGNIN_WITH_EMAIL_FORM_VALIDATION:
      return {
        ...state,
        signInWithEmailFormValidation: {},
      }
    case SET_NAME_FORM_VALIDATION:
      return {
        ...state,
        nameFormValidation: {
          ...state.nameFormValidation,
          [action.field]: action.message,
        },
      }
    case EMPTY_NAME_FORM_VALIDATION:
      return {
        ...state,
        nameFormValidation: {},
      }
    case SET_PASSWORD_FORM_VALIDATION:
      return {
        ...state,
        passwordFormValidation: {
          ...state.passwordFormValidation,
          [action.field]: action.message,
        },
      }
    case EMPTY_PASSWORD_FORM_VALIDATION:
      return {
        ...state,
        passwordFormValidation: {},
      }
    default:
      return state
  }
}
