import React, { useEffect, useRef } from 'react'
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from 'classnames'
import CancelBtn from '../../../../icons/cancelBtn.svg'
import AcceptBtn from '../../../../icons/acceptBtn.svg'
import Download from '../../../../icons/download.svg'
import Edit from '../../../../icons/edit.svg'
import TypingEffect from '../TypingEffect'
import { setLegalRepresentations } from '../../../../redux/actions/careReceipients'
import {
  setEditFromSummary,
  setLegalRepresentation,
} from '../../../../redux/actions/quizQuestions'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFile } from '../../../../api/formApi'
import { setConfirmationModal } from '../../../../redux/actions/confirmationModal'
import LegalModalComponent from '../NestedComponentOfModal/LegalModalComponent'
import { setToastNotification } from '../../../../redux/actions/toastNotification'

const LegalChallenge = ({
  quizLegal,
  indexLegal,
  notLegalAnsweredIndex,
  editList,
  setEdit,
  getTextValue,
  handleLegalTextAnswer,
  edit,
  handleEdit,
  handleCancel,
  legalRepresentations,
  messageRefs,
}) => {
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  const fileName = quizLegal.find((item) => item.type === 'file')?.fileName
  const {
    payment: { reimbursementRef },
    quizQuestions: { index, groupName, editSummary },
  } = useSelector((state) => state)

  useEffect(() => {
    quizLegal.map((item, i) => {
      if (!!item.edit && index === i) {
        item.type === 'phoneNumber' && handleModalLegal(item, i)
      }
    })
  }, [groupName])

  const handleFileChange = async (e, index) => {
    const file = e.target.files[0]

    // Ensure a file is selected
    if (!file) {
      console.error('No file selected')
      alert('Please select a file to upload.')
      return
    }

    // Create FormData object with the selected file
    const formData = new FormData()
    formData.append('file', file)

    try {
      // Upload the file
      const res = await uploadFile(
        formData,
        reimbursementRef,
        'RC_LEGAL_REPRESENTATION',
      )

      // Update the quizLegal array
      const updatedQuiz = quizLegal.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            answers: res.amazonUrl, // Set the uploaded file's URL
            fileName: file.name, // Set the file's name
          }
        }
        return item
      })

      // Dispatch actions to update the state
      dispatch(
        setLegalRepresentations(
          Object.keys(legalRepresentations)[index],
          res.amazonUrl,
        ),
      )
      dispatch(setLegalRepresentation(updatedQuiz, indexLegal))
      dispatch(setEditFromSummary(''))
    } catch (error) {
      console.error('File upload failed:', error)
      alert('An error occurred during the file upload. Please try again.')
    }
  }
  const handleDivClick = () => {
    // Simulate click on hidden input when div is clicked
    fileInputRef.current.click()
  }
  const downloadFile = (url, name) => {
    if (url === '-') {
      return null
    } else {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}`)
          document.body.appendChild(link)
          link.click()
          link?.parentNode?.removeChild(link)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const handleModalLegal = (item, i) => {
    dispatch(
      setConfirmationModal({
        headerText: 'Edit',
        body: <LegalModalComponent item={item} legalIndex={i} />,
        large: true,
      }),
    )
  }
  return (
    <>
      {quizLegal.slice(0, notLegalAnsweredIndex + 1).map((item, i) => {
        return (
          <>
            <li
              key={item.questionKey}
              className={styles.odd}
              ref={(el) => (messageRefs.current['legalRepresentations' + i] = el)}
            >
              <img src={item?.img} alt={item?.img} />
              <p>
                {item.answers ? (
                  item.question
                ) : (
                  <TypingEffect text={item?.question} speed={50} />
                )}
              </p>
            </li>
            {item?.answers && (
              <li
                className={cx(styles.even, {
                  [styles.fileStyle]: item?.type === 'file',
                  [styles.hideList]: editList !== 1,
                  [styles.borderLi]:
                    !!item.edit && (item.type === 'text' || item.type === 'number'),
                })}
                onMouseOver={() => setEdit(i)}
                onMouseOut={() => setEdit(-1)}
              >
                {!!item.edit && (item.type === 'text' || item.type === 'number') ? (
                  <>
                    <textarea
                      maxLength={`${item.type === 'number' && 5}`}
                      onChange={(e) => {
                        item.type === 'number' && !Number(e.currentTarget.value)
                          ? dispatch(
                              setToastNotification({
                                type: 'robotik',
                                // toastTittle: 'Successfully sent',
                                toastBody:
                                  'Hey! there is an error! You need to provide only numbers',
                              }),
                            )
                          : getTextValue(e)
                      }}
                      autoFocus={true}
                    >
                      {item?.answers}
                    </textarea>
                    <div>
                      <img
                        src={CancelBtn}
                        alt={CancelBtn}
                        onClick={() => handleCancel(i)}
                      />
                      <img
                        src={AcceptBtn}
                        alt={AcceptBtn}
                        onClick={() => {
                          handleLegalTextAnswer(i)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>
                    {/*{item.type==='file' && item.fileName}*/}
                    {/*{item.type==='phoneNumber'&&  item?.answers}*/}
                    {item.type === 'file' ? item.fileName : item?.answers}
                    {item?.type === 'file' && (
                      <img
                        src={Download}
                        alt={Download}
                        onClick={() =>
                          downloadFile(legalRepresentations.document, fileName)
                        }
                      />
                    )}
                    {edit === i && !editSummary && (
                      <img
                        src={Edit}
                        alt={Edit}
                        onClick={() =>
                          item.type === 'file'
                            ? handleDivClick()
                            : item.type === 'phoneNumber'
                              ? handleModalLegal(item, i)
                              : handleEdit(i)
                        }
                      />
                    )}
                    {item.type === 'file' && (
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, i)} // Pass event and index
                        accept="image/jpeg,image/png,.pdf"
                      />
                    )}
                  </p>
                )}
              </li>
            )}
          </>
        )
      })}
    </>
  )
}
export default LegalChallenge
