import { userDetails } from '../../defaultStates'
import { SET_USER_DETAILS, SET_USER_EMAIL } from '../../ActionTypes'

export default (state = userDetails, action = {}) => {
  switch (action.type) {
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case SET_USER_DETAILS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
