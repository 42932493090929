import { startChallengeSteps } from '../../defaultStates'
import { SET_CHALLENGE_STEPS } from '../../ActionTypes'

export default (state = startChallengeSteps, action = {}) => {
  switch (action.type) {
    case SET_CHALLENGE_STEPS:
      return {
        [action.step]: action.challenge,
      }
    default:
      return state
  }
}
