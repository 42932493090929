import React, { useEffect } from 'react'
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import cx from 'classnames'
import CancelBtn from '../../../../icons/cancelBtn.svg'
import AcceptBtn from '../../../../icons/acceptBtn.svg'
import Download from '../../../../icons/download.svg'
import Edit from '../../../../icons/edit.svg'
import TypingEffect from '../TypingEffect'
import { useDispatch, useSelector } from 'react-redux'
import { setConfirmationModal } from '../../../../redux/actions/confirmationModal'
import ActiveModalComponent from '../NestedComponentOfModal/ActiveModalComponent'
import { setToastNotification } from '../../../../redux/actions/toastNotification'
import {
  setCommercially,
  setIndividual,
} from '../../../../redux/actions/careReceipients'
import CheckboxChecked from '../../../../icons/checkbox-checked.svg'
import CheckboxUnchecked from '../../../../icons/checkbxo-unchecked.svg'

const CommerciallyActiveChallenge = ({
  indexActive,
  quizActive,
  notActiveAnsweredIndex,
  editList,
  setEdit,
  getTextValue,
  handleActiveTextAnswer,
  edit,
  handleEdit,
  handleCancel,
  messageRefs,
}) => {
  const {
    representationByCommerciallyActive,
    quizQuestions: { editSummary, groupName, index, availableDays },
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  let notObjIndex =
    indexActive - representationByCommerciallyActive.activeDates.length

  useEffect(() => {
    quizActive.map((item, i) => {
      if (!!item.edit && index === i) {
        console.log(item)
        ;(item.type === 'date' ||
          item.questionKey === 'availableDates' ||
          item.questionKey === 'durationOfAbsence' ||
          item.type === 'submit') &&
          handleActiveModal(item, i)
      }
    })
  }, [groupName])
  const handleActiveModal = (item, i) => {
    dispatch(
      setConfirmationModal({
        headerText: 'Edit',
        body: <ActiveModalComponent item={item} activeIndex={i} />,
        large: true,
      }),
    )
  }
  const handleConstant = () => {
    if (representationByCommerciallyActive.ConsentToTheData === '') {
      dispatch(
        setCommercially(
          Object.keys(representationByCommerciallyActive)[4],
          'checked',
        ),
      )
    } else {
      dispatch(
        setCommercially(Object.keys(representationByCommerciallyActive)[4], ''),
      )
    }
  }
  const handleIAgree = () => {
    if (representationByCommerciallyActive.iAgree === '') {
      dispatch(
        setCommercially(
          Object.keys(representationByCommerciallyActive)[5],
          'checked',
        ),
      )
    } else {
      dispatch(
        setCommercially(Object.keys(representationByCommerciallyActive)[5], ''),
      )
    }
  }

  const handleIHave = () => {
    if (representationByCommerciallyActive.iHave === '') {
      dispatch(
        setCommercially(
          Object.keys(representationByCommerciallyActive)[6],
          'checked',
        ),
      )
    } else {
      dispatch(
        setCommercially(Object.keys(representationByCommerciallyActive)[6], ''),
      )
    }
  }
  const handlepaid = () => {
    if (representationByCommerciallyActive.paid === '') {
      dispatch(
        setCommercially(
          Object.keys(representationByCommerciallyActive)[7],
          'checked',
        ),
      )
    } else {
      dispatch(
        setCommercially(Object.keys(representationByCommerciallyActive)[7], ''),
      )
    }
  }
  return (
    <>
      {quizActive.slice(0, notActiveAnsweredIndex + 1).map((item, i) => {
        return (
          <>
            {/*{item.question && <li key={item.questionKey} className={styles.odd}*/}
            {/*     ref={el => (messageRefs.current['representationByCommerciallyActive' + i] = el)}>*/}
            {/*    <img src={item?.img} alt={item?.img}/>*/}
            {/*    <p>*/}
            {/*        {*/}
            {/*            typeof item.answers === 'object' && item.answers.date && item.answers? item.question :*/}
            {/*                <TypingEffect text={item?.question} speed={50}/>*/}
            {/*        }*/}
            {/*    </p>*/}
            {/*</li>}*/}
            {item.question && (
              <li
                key={item.questionKey}
                className={cx(styles.odd)}
                ref={(el) =>
                  (messageRefs.current['representationByCommerciallyActive' + i] =
                    el)
                }
              >
                {item.type === 'constant' ? (
                  <p className={styles.checkbox}>
                    <img
                      className={styles.checkboxStyles}
                      src={
                        representationByCommerciallyActive.ConsentToTheData
                          ? CheckboxChecked
                          : CheckboxUnchecked
                      }
                      alt={'Select'}
                      onClick={() => handleConstant(i)}
                    />
                    {item.answers ||
                    representationByCommerciallyActive.ConsentToTheData ? (
                      item.question
                    ) : (
                      <TypingEffect text={item?.question} speed={50} />
                    )}
                  </p>
                ) : item.type === 'iAgree' ? (
                  representationByCommerciallyActive.ConsentToTheData ===
                    'checked' && (
                    <p className={styles.checkbox}>
                      <img
                        className={styles.checkboxStyles}
                        src={
                          representationByCommerciallyActive.iAgree
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                        alt={'Select'}
                        onClick={() => handleIAgree(i)}
                      />
                      {item.answers || representationByCommerciallyActive.iAgree ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  )
                ) : item.type === 'iHave' ? (
                  representationByCommerciallyActive.iAgree === 'checked' &&
                  representationByCommerciallyActive.ConsentToTheData ===
                    'checked' && (
                    <p className={styles.checkbox}>
                      <img
                        className={styles.checkboxStyles}
                        src={
                          representationByCommerciallyActive.iHave
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                        alt={'Select'}
                        onClick={() => handleIHave(i)}
                      />
                      {item.answers || representationByCommerciallyActive.iHave ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  )
                ) : item.type === 'paid' ? (
                  representationByCommerciallyActive.iAgree === 'checked' &&
                  representationByCommerciallyActive.ConsentToTheData ===
                    'checked' &&
                  representationByCommerciallyActive.iHave === 'checked' && (
                    <p className={styles.checkbox}>
                      <img
                        className={styles.checkboxStyles}
                        src={
                          representationByCommerciallyActive.paid
                            ? CheckboxChecked
                            : CheckboxUnchecked
                        }
                        alt={'Select'}
                        onClick={() => handlepaid(i)}
                      />
                      {item.answers || representationByCommerciallyActive.paid ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  )
                ) : (
                  <>
                    <img src={item?.img} alt={item?.img} />
                    <p>
                      {typeof item.answers === 'object' &&
                      item.answers.date &&
                      item.answers ? (
                        item.question
                      ) : (
                        <TypingEffect text={item?.question} speed={50} />
                      )}
                    </p>
                  </>
                )}
              </li>
            )}
            {((typeof item.answers === 'object' && item.answers.date) ||
              (typeof item.answers !== 'object' && item.answers)) && (
              <li
                className={cx(styles.even, {
                  [styles.fileStyle]: item?.type === 'file',
                  [styles.hideList]: editList !== 1,
                  [styles.borderLi]:
                    !!item.edit && (item.type === 'text' || item.type === 'integer'),
                })}
                onMouseOver={() => setEdit(i)}
                onMouseOut={() => setEdit(-1)}
              >
                {!!item.edit && (item.type === 'text' || item.type === 'integer') ? (
                  <>
                    <textarea
                      maxLength={`${item.type === 'number' && 5}`}
                      onChange={(e) => {
                        item.type === 'number' && !Number(e.currentTarget.value)
                          ? dispatch(
                              setToastNotification({
                                type: 'robotik',
                                // toastTittle: 'Successfully sent',
                                toastBody:
                                  'Hey! there is an error! You need to provide only numbers',
                              }),
                            )
                          : getTextValue(e)
                      }}
                      autoFocus={true}
                    >
                      {item?.answers}
                    </textarea>
                    <div>
                      <img
                        src={CancelBtn}
                        alt={CancelBtn}
                        onClick={() => handleCancel(i)}
                      />
                      <img
                        src={AcceptBtn}
                        alt={AcceptBtn}
                        onClick={() => {
                          handleActiveTextAnswer(i, item.questionKey)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>
                    {typeof item.answers !== 'object' ? (
                      <p>{item.answers}</p>
                    ) : (
                      <p>
                        {item.answers?.date && (
                          <p>
                            <span>Datum:</span> <span>{item.answers?.date}</span>
                          </p>
                        )}
                        {item.answers?.duration && (
                          <p>
                            <span>Dauer:</span>
                            <span>{item.answers?.duration}</span>
                          </p>
                        )}
                        {item.answers?.amount && (
                          <p>
                            <span>Betrag:</span>
                            <span>{item.answers?.amount}</span>
                          </p>
                        )}
                      </p>
                    )}

                    {item?.type === 'file' && <img src={Download} alt={Download} />}
                    {edit === i && !editSummary && (
                      <img
                        src={Edit}
                        alt={Edit}
                        onClick={() =>
                          item.type === 'date' ||
                          item.type === 'group' ||
                          item.type === 'submit'
                            ? handleActiveModal(item, i)
                            : handleEdit(i)
                        }
                      />
                    )}
                  </p>
                )}
              </li>
            )}
          </>
        )
      })}
    </>
  )
}
export default CommerciallyActiveChallenge
