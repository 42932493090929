import React, { useState, useEffect } from 'react'
import styles from '../modalComponent.module.scss'
import FlexiiLogo from '../../../../../icons/flexiiLogo.svg'
import Calendar from 'react-calendar'
import moment from 'moment/moment'
import RightArrow from '../../../../../icons/rightArrowIcon.svg'
import LeftArrow from '../../../../../icons/leftArrowIcon.svg'
import Button from '../../../../UiKitComponents/Button'
import { closeConfirmationModal } from '../../../../../redux/actions/confirmationModal'
import { setAuthorizedPerson } from '../../../../../redux/actions/careReceipients'
import {
  setAuthPerson,
  setEditFromSummary,
} from '../../../../../redux/actions/quizQuestions'
import { handlePhoneNumberFieldChange } from '../../../../../redux/actions/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { reasonsOfAbsence } from '../../../../../constants/lists/relationshipList'
import cx from 'classnames'
import Arrow from '../../../../../icons/smallArrow.svg'
import PhoneNumberField from '../../../../ReusableField/PhoneNumberField'
import countryPickerData from '../../../../../constant/countryPickerData'

const AuthModalComponent = ({ item, authIndex }) => {
  const [answerAuth, setAnswerAuth] = useState(
    item.type === 'phoneNumber' ? item.answers || '' : '',
  )
  const [option, setOption] = useState(false)
  const dispatch = useDispatch()
  const {
    quizAuthorizedPerson: { quizAuth, indexAuth },
  } = useSelector((state) => state.quizQuestions)
  const {
    authorizedPerson,
    authorizedPerson: { periodOfAbsenceFrom, periodOfAbsenceTo },
  } = useSelector((state) => state)
  // const { phoneNumberObj } = useSelector((state) => state.auth)
  const [phoneNumberObj, setPhoneNumberData] = useState({})
  const defaultPhoneObj = useSelector((state) => state.auth).phoneNumberObj

  useEffect(() => {
    if (item.type === 'phoneNumber') {
      if (item.answers !== '-') {
        const mobileArr = item.answers?.slice(1)?.split(' ')

        const countryCode = countryPickerData.find(
          (countryObj) => countryObj.callingCode === mobileArr[0],
        ).key

        setPhoneNumberData({
          callingCode: mobileArr[0],
          number: mobileArr[1],
          countryCode,
        })
      } else {
        setPhoneNumberData(defaultPhoneObj)
      }
    }
  }, [])

  const handleChangeAuthCalendar = (e) => {
    setAnswerAuth(moment(e).format('DD.MM.YYYY'))
  }
  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split('.')
    const date = new Date(year, month - 1, day)
    return date.toString()
  }
  // console.log(convertToDate(answerAuth));
  const handleChange = (e) => {
    setAnswerAuth(e.currentTarget.value)
  }
  const authOption = () => {
    if (!option) {
      setOption(true)
    } else {
      setOption(false)
    }
  }
  const clickAuthOption = (e) => {
    setAnswerAuth(e.target.innerText)
    setOption(false)
  }
  const handleAuthAnswer = (index) => {
    // if(!answerAuth) return
    const newQuiz = quizAuth.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          // answers: answerAuth,
          answers:
            item.type !== 'phoneNumber'
              ? answerAuth
              : item.type === 'phoneNumber' && (!answerAuth || answerAuth === '-')
                ? '-'
                : `+${phoneNumberObj.callingCode} ` +
                  '' +
                  `${phoneNumberObj.number}`,
          returnQuestion:
            item.question ===
              'Wird die verhinderte Pflegeperson vom Pflegebedürftigen als Pfleger bezahlt oder ist sogar eine Pflegefirma ?' &&
            answerAuth === 'Ja'
              ? 'Erstattung von Verhinderungspflege ist nur möglich bei Verhinderung eines privaten, nicht für den Berechtigten gewerblich tätigen Pflegeperson.'
              : item.question ===
                    'Wie lange hat die verhinderte Pflegeperson schon den Antragsteller gepflegt ?' &&
                  answerAuth === 'Unter 6 monate'
                ? 'Erstattung der Verhinderungspflege ist erst ab 6 Monaten Vorpflegezeit möglich.'
                : '',
        }
      }
      return item
    })
    dispatch(
      setAuthorizedPerson(
        Object.keys(authorizedPerson)[index],
        // Object.keys(authorizedPerson)[index] === 'phoneNumber' ? `+${phoneNumberObj.callingCode} `+``+`${answerAuth}` : answerAuth
        Object.keys(authorizedPerson)[index] !== 'phoneNumber'
          ? answerAuth
          : item.type === 'phoneNumber' && (!answerAuth || answerAuth === '-')
            ? '-'
            : `+${phoneNumberObj.callingCode} ` + '' + `${phoneNumberObj.number}`,
      ),
    )
    dispatch(setAuthPerson(newQuiz, indexAuth))
    setAnswerAuth('')
  }
  const handleChangePhone = (newPhoneNumberObj) => {
    // dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    setPhoneNumberData(newPhoneNumberObj)
  }
  return (
    <section className={styles.modalComponent}>
      <p className={styles.odd}>
        <img src={item?.img && FlexiiLogo} alt={FlexiiLogo} />
        <p>{item.question}</p>
      </p>
      {item.type === 'phoneNumber' && (
        <PhoneNumberField
          onChange={handleChangePhone}
          phoneNumberObj={phoneNumberObj}
          setAnswerr={setAnswerAuth}
          value={answerAuth}
        />
      )}
      {item.type === 'birthDay' && (
        <Calendar
          onChange={(e) => handleChangeAuthCalendar(e)}
          value={
            authorizedPerson.birthday === '-' && !answerAuth
              ? new Date(moment().year(), moment().month(), moment().date())
              : authorizedPerson.birthday !== '-' && !answerAuth
                ? new Date(convertToDate(authorizedPerson.birthday))
                : convertToDate(answerAuth)
          }
          maxDate={new Date()}
          className={styles.reactCalendar}
          nextLabel={<img src={RightArrow} alt={'next'} />}
          prevLabel={<img src={LeftArrow} alt={'Previous'} />}
          next2Label={null}
          prev2Label={null}
          locale={'de'}
        />
      )}
      {item.type === 'date' &&
        item.question ===
          'Ab wann ist die normalerweise pflegende Person verhindert oder verhindert gewesen ?' && (
          <Calendar
            onChange={(e) => handleChangeAuthCalendar(e)}
            value={
              authorizedPerson.periodOfAbsenceFrom === '-' && !answerAuth
                ? new Date(moment().year(), moment().month(), moment().date())
                : authorizedPerson.periodOfAbsenceFrom !== '-' && !answerAuth
                  ? new Date(convertToDate(authorizedPerson.periodOfAbsenceFrom))
                  : convertToDate(answerAuth)
            }
            maxDate={
              periodOfAbsenceTo ? new Date(convertToDate(periodOfAbsenceTo)) : null
            }
            className={styles.reactCalendar}
            nextLabel={<img src={RightArrow} alt={'next'} />}
            prevLabel={<img src={LeftArrow} alt={'Previous'} />}
            next2Label={null}
            prev2Label={null}
            locale={'de'}
          />
        )}
      {item.type === 'date' && item.question === 'Und bis wann ?' && (
        <Calendar
          onChange={(e) => handleChangeAuthCalendar(e)}
          value={
            authorizedPerson.periodOfAbsenceTo === '-' && !answerAuth
              ? new Date(moment().year(), moment().month(), moment().date())
              : authorizedPerson.periodOfAbsenceTo !== '-' && !answerAuth
                ? new Date(convertToDate(authorizedPerson.periodOfAbsenceTo))
                : convertToDate(answerAuth)
          }
          minDate={
            periodOfAbsenceFrom ? new Date(convertToDate(periodOfAbsenceFrom)) : null
          }
          // maxDate={periodOfAbsenceTo ? new Date(convertToDate(periodOfAbsenceTo)) : null}
          className={styles.reactCalendar}
          nextLabel={<img src={RightArrow} alt={'next'} />}
          prevLabel={<img src={LeftArrow} alt={'Previous'} />}
          next2Label={null}
          prev2Label={null}
          locale={'de'}
        />
      )}
      {item.type === 'option' && (
        <div className={styles.optionHid}>
          <div
            className={cx(styles.select, { [styles.imgRotate]: !!option })}
            onClick={authOption}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleAuthAnswer()
            }}
          >
            <img src={Arrow} alt={Arrow} />
            {answerAuth ? answerAuth : authorizedPerson.reasonForTheAbsence}
          </div>
          {!!option && (
            <div className={styles.option}>
              {reasonsOfAbsence.map((item) => {
                return <div onClick={clickAuthOption}>{item}</div>
              })}
            </div>
          )}
        </div>
      )}
      {item.type === 'submit' && (
        <span>
          <input
            type={'submit'}
            placeholder={'Geben Sie eine Antwort ein.'}
            value={
              item.question ===
              'Wird die verhinderte Pflegeperson vom Pflegebedürftigen als Pfleger bezahlt oder ist sogar eine Pflegefirma ?'
                ? 'Ja'
                : item.question ===
                    'Wie lange hat die verhinderte Pflegeperson schon den Antragsteller gepflegt ?'
                  ? 'Unter 6 monate'
                  : 'unter 8 Stunden täglich'
            }
            onClick={(e) => handleChange(e)}
            className={styles.care}
            autoFocus={
              authorizedPerson.periodOfCare === 'Unter 6 monate' ||
              authorizedPerson.durationOfAbsence === 'unter 8 Stunden täglich'
            }
          />
          <input
            type={'submit'}
            placeholder={'Geben Sie eine Antwort ein.'}
            value={
              item.question ===
              'Wird die verhinderte Pflegeperson vom Pflegebedürftigen als Pfleger bezahlt oder ist sogar eine Pflegefirma ?'
                ? 'Nein'
                : item.question ===
                    'Wie lange hat die verhinderte Pflegeperson schon den Antragsteller gepflegt ?'
                  ? 'über 6 Monate'
                  : 'über 8 Stunden täglich'
            }
            onClick={(e) => handleChange(e)}
            className={styles.care}
            autoFocus={
              authorizedPerson.periodOfCare === 'über 6 Monate' ||
              authorizedPerson.durationOfAbsence === 'über 8 Stunden täglich'
            }
          />
        </span>
      )}
      <div>
        <Button
          small
          primaryOutlined
          onClick={() => {
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Abbrechen'}
        </Button>
        <Button
          primary
          small
          onClick={() => {
            handleAuthAnswer(authIndex)
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Speichern'}
        </Button>
      </div>
    </section>
  )
}
export default AuthModalComponent
