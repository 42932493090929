import React, { useEffect, useRef, useState } from 'react'
import styles from './header.module.scss'
import cx from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ButtonDropDown from '../../../UiKitComponents/ButtonDropDown/ButtonDropDown'
import { setSelectedLanguage } from '../../../../redux/actions/metaData'
import {
  logOutUser,
  setUserDetails,
  setUserEmail,
} from '../../../../redux/actions/authActions'
import AccountDropDown from '../../../UiKitComponents/AccountDropDown'
import Summary from '../../../../icons/summary.svg'
import { getUserData } from '../../../../api/userApi'
import logOut from '../../../../redux/thunks/account/logOut'
import Details from '../../../NestedComponents/Option 1/Details'

const Header = () => {
  const dispatch = useDispatch()
  const { pathname } = window.location
  let navigate = useNavigate()
  const { selectedLanguage } = useSelector((state) => state.metadata)
  const accessToken = localStorage.getItem('accessToken')
  const [showDetails, setShowDetails] = useState(false)
  const [windowWidth, setWindowWidth] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const handleResize = () => {
    setWindowWidth({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    accessToken &&
      getUserData()
        .then((data) => {
          dispatch(setUserDetails(data))
        })
        .catch((err) => console.log(err))
  }, [])

  const handleChangeLanguage = (value) => {
    dispatch(setSelectedLanguage(value))
    // i18next.changeLanguage(value).then(() => dispatch(setSelectedLanguage(value)))
    localStorage.setItem('language', value)
  }

  const handleLogoClick = () => {
    if (!localStorage.getItem('accessToken')) {
      navigate('/')
      dispatch(logOut())
    } else {
      navigate('/profile')
    }
  }

  const handleShow = () => {
    if (!showDetails) {
      setShowDetails(true)
    } else setShowDetails(false)
  }
  return (
    <header
      className={cx(styles.header, { [styles.sum]: pathname === '/startChallenge' })}
    >
      <span onClick={handleLogoClick}></span>
      {windowWidth.width < 1320 && pathname === '/startChallenge' && (
        <div>
          <span>
            <p>Summary</p>
            <img src={Summary} alt={Summary} onClick={handleShow} />
          </span>
          {!!showDetails && <Details />}
        </div>
      )}
      <div>
        <ButtonDropDown
          value={selectedLanguage.toUpperCase()}
          options={[
            { label: 'English', value: 'en' },
            { label: 'Deutsch', value: 'de' },
          ]}
          onChange={handleChangeLanguage}
        />
        <div>{accessToken && <AccountDropDown />}</div>
      </div>
    </header>
  )
}

export default Header
