import React, { useState, useEffect } from 'react'
import styles from '../modalComponent.module.scss'
import FlexiiLogo from '../../../../../icons/flexiiLogo.svg'
import Button from '../../../../UiKitComponents/Button'
import { closeConfirmationModal } from '../../../../../redux/actions/confirmationModal'
import {
  setEmptyActive,
  setEmptyPrivate,
  setEmptyRep,
  setRepresentative,
} from '../../../../../redux/actions/careReceipients'
import {
  setCommerciallyActive,
  setEditFromSummary,
  setPrivateIndividual,
  setRepresentativePerson,
} from '../../../../../redux/actions/quizQuestions'
import { handlePhoneNumberFieldChange } from '../../../../../redux/actions/Auth'
import { useDispatch, useSelector } from 'react-redux'
import {
  otherRelationshipList,
  relationshipByMarriageList,
  relationshipList,
} from '../../../../../constants/lists/relationshipList'
import cx from 'classnames'
import Arrow from '../../../../../icons/smallArrow.svg'
import PhoneNumberField from '../../../../ReusableField/PhoneNumberField'
import countryPickerData from '../../../../../constant/countryPickerData'
import {
  listEntryRepresentationByPrivateIndividual,
  representativeQuestions,
} from '../../../../../constants/quizConstant'
import {
  quizQuestions,
  representationByCommerciallyActive,
  representationByPrivateIndividual,
  representativePerson,
} from '../../../../../redux/defaultStates'

const RepModalComponent = ({ item, repIndex }) => {
  const {
    quizRepresentativePerson: { quizRep, indexRep },
  } = useSelector((state) => state.quizQuestions)
  const { representativePerson } = useSelector((state) => state)
  // const { phoneNumberObj } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [answerRep, setAnswerRep] = useState(item.answers || '')
  const [repOption, setRepOption] = useState(false)
  const [phoneNumberObj, setPhoneNumberData] = useState({})
  const defaultPhoneObj = useSelector((state) => state.auth).phoneNumberObj

  useEffect(() => {
    if (item.type === 'phoneNumber') {
      if (item.answers !== '-') {
        console.log('item.answers', item.answers)
        const mobileArr = item.answers?.slice(1)?.split(' ')

        const countryCode = countryPickerData.find(
          (countryObj) => countryObj.callingCode === mobileArr[0],
        ).key

        setPhoneNumberData({
          callingCode: mobileArr[0],
          number: mobileArr[1],
          countryCode,
        })
      } else {
        setPhoneNumberData(defaultPhoneObj)
      }
    }
  }, [])

  const handleChangePhone = (newPhoneNumberObj) => {
    setPhoneNumberData(newPhoneNumberObj)
    // dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
  }

  // const handleChangePhone = (newPhoneNumberObj) => {
  //     dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
  // }
  const clickRepOption = () => {
    if (!repOption) {
      setRepOption(true)
    } else {
      setRepOption(false)
    }
  }
  const clickOptionOfRep = (e) => {
    setAnswerRep(e.target.innerText)
    setRepOption(false)
  }
  const handleChange = (e) => {
    setAnswerRep(e.currentTarget.value)
  }
  const handleRepAnswer = (index) => {
    const newQuiz = quizRep.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          // answers:  answerRep,
          answers:
            item.type !== 'phoneNumber'
              ? answerRep
              : item.type === 'phoneNumber' && (!answerRep || answerRep === '-')
                ? '-'
                : `+${phoneNumberObj.callingCode} ` +
                  '' +
                  `${phoneNumberObj.number}`,
          returnQuestion:
            item.question ===
              'Ist die Vertretung gewerblich als Pflegefachkraft tätig oder sogar eine Pflegefirma ?' &&
            answerRep === 'Nein'
              ? 'Vertretende Person ist privat und nur gelegentlich als PflegerIn tätig.'
              : item.question ===
                    'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste.' &&
                  answerRep !== 'nicht verwandt'
                ? 'Ersatz von Kosten der Verhinderungspflege ist bei Personen in Verwandschaft, Lebensgemeinschaft und Ehepartnern nur eingeschränkt möglich.'
                : item.question ===
                      'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.' &&
                    answerRep !== 'Nicht verheiratet oder verschwägert'
                  ? 'Ersatz von Kosten der Verhinderungspflege ist bei Verschwägerten Personen nur eingeschränkt möglich.'
                  : item.question ===
                        'Leben Vertretung und Antragsteller im selben Haushalt ?' &&
                      answerRep === 'Ja'
                    ? 'Ersatz von Kosten der Verhinderungspflege ist bei dauernd im gleichen Haushalt lebenden Personen nur eingeschränkt möglich.'
                    : '',
        }
      }
      return item
    })
    dispatch(
      setRepresentative(
        Object.keys(representativePerson)[index],
        // Object.keys(representativePerson)[index] === 'phoneNumber' ? `+${phoneNumberObj.callingCode} `+``+`${answerRep}` : answerRep
        Object.keys(representativePerson)[index] !== 'phoneNumber'
          ? answerRep
          : item.type === 'phoneNumber' && (!answerRep || answerRep === '-')
            ? '-'
            : `+${phoneNumberObj.callingCode} ` + '' + `${phoneNumberObj.number}`,
      ),
    )
    // dispatch(setRepresentativePerson(newQuiz, indexRep))
    if (index >= 9) {
      dispatch(setRepresentativePerson(newQuiz, indexRep))
    }
    if (answerRep === 'Nein' && index === 7) {
      console.log(1)
      dispatch(setRepresentative(Object.keys(representativePerson)[8], ''))
      const tupdatedQuestions = [
        ...newQuiz.slice(0, 8),
        ...representativeQuestions.slice(8),
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 8))
      dispatch(
        setEmptyRep({
          ...representativePerson,
          personRepresenting: 'Nein',
          commerciallyActive: '',
          relationshipList: '',
          relationshipByMarriage: '',
          sameHousehold: '',
          relationship: '',
        }),
      )
    }
    if (answerRep === 'Ja' && index === 7) {
      console.log(2)
      const tupdatedQuestions = [
        ...newQuiz.slice(0, 8), // Get the first 4 elements
        newQuiz[newQuiz.length - 1], // Get the last element
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 8))
      dispatch(
        setEmptyRep({
          ...representativePerson,
          personRepresenting: 'Ja',
          commerciallyActive: '',
          relationshipList: '',
          relationshipByMarriage: '',
          sameHousehold: '',
          relationship: '',
        }),
      )
    }
    if (answerRep === 'Ja' && index === 7 && representativePerson.relationship) {
      console.log(2)
      const tupdatedQuestions = [
        ...newQuiz.slice(0, 8), // Get the first 4 elements
        newQuiz[newQuiz.length - 1], // Get the last element
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 9))
      dispatch(
        setEmptyRep({
          ...representativePerson,
          personRepresenting: 'Ja',
          commerciallyActive: '',
          relationshipList: '',
          relationshipByMarriage: '',
          sameHousehold: '',
        }),
      )
    }
    if (answerRep === 'Nein' && index === 8) {
      console.log(3)

      const tupdatedQuestions = [
        ...newQuiz.slice(0, 9),
        ...representativeQuestions.slice(9),
      ]
      dispatch(setRepresentativePerson(tupdatedQuestions, 9))
      dispatch(
        setEmptyRep({
          ...representativePerson,
          commerciallyActive: 'Nein',
          relationshipList: '',
          relationshipByMarriage: '',
          sameHousehold: '',
          relationship: '',
        }),
      )
    }

    if (answerRep === 'Ja' && index === 8) {
      console.log(4)

      const tupdatedQuestions = [...newQuiz.slice(0, 9), newQuiz[newQuiz.length - 1]]
      dispatch(setRepresentativePerson(tupdatedQuestions, 9))
      dispatch(
        setEmptyRep({
          ...representativePerson,
          commerciallyActive: 'Ja',
          relationshipList: '',
          relationshipByMarriage: '',
          sameHousehold: '',
          relationship: '',
        }),
      )
    }

    dispatch(
      setPrivateIndividual([...quizQuestions.privateIndividual.quizPrivate], 0),
    )
    dispatch(
      setCommerciallyActive([...quizQuestions.commerciallyActive.quizActive], 0),
    )
    dispatch(setEmptyPrivate(representationByPrivateIndividual))
    dispatch(setEmptyActive(representationByCommerciallyActive))
    const autoUpdateQuiz =
      (representativePerson.relationshipList === 'nicht verwandt' ||
        representativePerson.relationshipList === 'andere Verwandtschaft') &&
      (representativePerson.relationshipByMarriage ===
        'Nicht verheiratet oder verschwägert' ||
        representativePerson.relationshipByMarriage ===
          'Anders verwandt oder verschwägert')
    if (
      ((answerRep === 'nicht verwandt' || answerRep === 'andere Verwandtschaft') &&
        (representativePerson.relationshipByMarriage ===
          'Nicht verheiratet oder verschwägert' ||
          representativePerson.relationshipByMarriage ===
            'Anders verwandt oder verschwägert')) ||
      ((representativePerson.relationshipList === 'nicht verwandt' ||
        representativePerson.relationshipList === 'andere Verwandtschaft') &&
        (answerRep === 'Nicht verheiratet oder verschwägert' ||
          answerRep === 'Anders verwandt oder verschwägert'))
    )
      autoUpdateQuiz &&
        dispatch(
          setPrivateIndividual(
            listEntryRepresentationByPrivateIndividual.filter(
              (item) =>
                item.questionKey !== 'representingPersonByTransport' &&
                item.questionKey !== 'representingPersonByCar' &&
                item.questionKey !== 'zipCodeFrom' &&
                item.questionKey !== 'zipCodeTo',
            ),
            0,
          ),
        )
    setAnswerRep('')
  }
  return (
    <section className={styles.modalComponent}>
      <p className={styles.odd}>
        <img src={item?.img && FlexiiLogo} alt={FlexiiLogo} />
        <p>{item.question}</p>
      </p>
      {item.type === 'phoneNumber' && (
        <PhoneNumberField
          onChange={handleChangePhone}
          phoneNumberObj={phoneNumberObj}
          setAnswerr={setAnswerRep}
          value={answerRep}
        />
      )}
      {item.type === 'option' && (
        <div className={styles.optionHid}>
          <div
            className={cx(styles.select, {
              [styles.imgRotate]: !!repOption,
              [styles.placeholder]: answerRep,
            })}
            onClick={clickRepOption}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleRepAnswer()
            }}
          >
            <img src={Arrow} alt={Arrow} />
            {answerRep
              ? answerRep
              : item.question === 'Wie oder wo haben Sie die Vertretung gefunden?'
                ? representativePerson.relationship
                : item.question ===
                    'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.'
                  ? representativePerson.relationshipByMarriage
                  : representativePerson.relationshipList}
          </div>
          {!!repOption && (
            <div className={styles.option}>
              {item.question ===
              'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste.'
                ? relationshipList.map((item) => {
                    return <div onClick={clickOptionOfRep}>{item}</div>
                  })
                : item.question ===
                    'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.'
                  ? relationshipByMarriageList.map((item) => {
                      return <div onClick={clickOptionOfRep}>{item}</div>
                    })
                  : otherRelationshipList.map((item) => {
                      return <div onClick={clickOptionOfRep}>{item}</div>
                    })}
            </div>
          )}
        </div>
      )}
      {item.type === 'submit' && (
        <span>
          <input
            type={'submit'}
            value={'Ja'}
            onClick={(e) => handleChange(e)}
            className={styles.care}
            autoFocus={item.answers}
          />
          <input
            type={'submit'}
            value={'Nein'}
            onClick={(e) => handleChange(e)}
            className={styles.care}
            autoFocus={item.answers}
          />
        </span>
      )}
      <div>
        <Button
          small
          primaryOutlined
          onClick={() => {
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Abbrechen'}
        </Button>
        <Button
          primary
          small
          onClick={() => {
            handleRepAnswer(repIndex)
            dispatch(closeConfirmationModal())
            dispatch(setEditFromSummary(''))
          }}
        >
          {'Speichern'}
        </Button>
      </div>
    </section>
  )
}
export default RepModalComponent
