import { CLOSE_TOAST_NOTIFICATION, SET_TOAST_NOTIFICATION } from '../../ActionTypes'

const setToastNotification = (notificationProps) => ({
  type: SET_TOAST_NOTIFICATION,
  notificationProps,
})

const closeToastNotification = () => ({
  type: CLOSE_TOAST_NOTIFICATION,
})

export { setToastNotification, closeToastNotification }
